import * as _ from 'lodash-es';

import { Component, Inject, Provider } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { TranslateService } from "@ngx-translate/core";
import { CalibratesRange } from "src/app/model/calibratesRange";
import { CalibrateTimeConfig, Calibration } from "src/app/model/calibration";
import { CalibrationSite } from "src/app/model/calibrationSite";
import { EquipmentType } from "src/app/model/equipment";
import { UnitVariable } from "src/app/model/unitVariable";
import { VariableType } from "src/app/model/VariableType";
import { CalibrateTimeConfigService } from "src/app/service/calibrate-time-config.service";
import { CalibratesRangeService } from "src/app/service/calibratesRange.service";
import { CalibrationSiteService } from "src/app/service/calibrationSite.service";
import { EquipmentTypeService } from "src/app/service/equipmentType.service";
import { ProviderService } from "src/app/service/provider.service";
import { SnackBarService } from "src/app/service/snackBar.service";
import { UnitVariableService } from "src/app/service/unitVariable.service";
import { VariableTypeService } from "src/app/service/variableType.service";
import { Constants } from '../../../utils/constants';
import { ParticleCountType } from '../../../model/particleCountType';
import { ParticleCountTypeService } from '../../../service/particleCountType.service';
import { CalibrationService } from 'src/app/service/calibration.service';

export interface CalibrationEditDialogData {
  calibration: Calibration;
  isEdit: boolean;
}

@Component({
  selector: 'app-quote-edit-dialog-calibration',
  templateUrl: './quote-edit-calibration-dialog.html',
})
export class CalibrationEditDialogComponent {
  tituloDialog: string
  equipmentTypes: EquipmentType[] = [];
  calibrateRanges: CalibratesRange[] = [];
  calibrationSites: CalibrationSite[] = [];
  providers: Provider[] = [];
  variableTypes: VariableType[] = [];
  unitVariableTypes: UnitVariable[] = [];
  searchText: string = '';
  equipmentType: EquipmentType = new EquipmentType();
  EQUIPMENT_TYPE_OTHER = Constants.EQUIPMENT_TYPE_OTHER;
  EQUIPMENT_TYPE_TEXTURE_ANALYZER = Constants.EQUIPMENT_TYPE_TEXTURE_ANALYZER;
  particleCountTypes: ParticleCountType[] = [];
  isParticleCounter= Constants.EQUIPMENT_TYPE_PARTICLE_COUNTER;
  equipmentTypesFiltered: EquipmentType[] = [];
  selectedVariableId: number;
  selectedEquipmentVariables: VariableType[] = [];
  particleCountFiltered: ParticleCountType[] = [];
  preassureRange: string;
  calibration: Calibration[]=[];
  calibrationTimeConfig: CalibrateTimeConfig;

  isOutsourcing: boolean = false;

  constructor(
    private equipmentTypeService: EquipmentTypeService,
    private calibrateRangeService: CalibratesRangeService,
    private calibrationSiteService: CalibrationSiteService,
    private providerService: ProviderService,
    private variableTypeService: VariableTypeService,
    private unitVariableService: UnitVariableService,
    private calibrateTimeConfigService: CalibrateTimeConfigService,
    private particleCountTypeService: ParticleCountTypeService,
    public snackBarService: SnackBarService,
    private translate: TranslateService,
    private calibrationService: CalibrationService,
    private dialogRef: MatDialogRef<CalibrationEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CalibrationEditDialogData
  ) {
    if (data.isEdit) {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.calibrates.title.edit', { name: data.calibration.id }) as string;
    } else {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.calibrates.title.create') as string;
    }
    if (data.calibration.provider != null) {
      data.calibration.outsourced = true;
    }
  }

  ngOnInit(): void {
    this.equipmentTypeService.findAllByIdAplication(3).subscribe((res: EquipmentType[]) => {
      this.equipmentTypes = res;
      this.equipmentTypesFiltered = _.cloneDeep(res);
    });

    this.calibrationSiteService.findAll().subscribe((res: CalibrationSite[]) => {
      this.calibrationSites = res;
    });

    this.providerService.findAll().subscribe((res: Provider[]) => {
      this.providers = res;
    });

    this.unitVariableService.findAll().subscribe((res: VariableType[]) => {
      this.variableTypes = res;
    });
  
    this.particleCountTypeService.findAll().subscribe((res: ParticleCountType[]) => {
      this.particleCountTypes = res;
      this.particleCountFiltered = res
    });
    if (this.data.calibration.variableType) {
      this.loadVariableTypesByEquipmentType();

      if (this.data.isEdit) {
        this.calibrateRangeService.findAllByVariableAndEquipment(this.data.calibration.variableType, this.data.calibration.equipmentType, this.data.calibration.unitType).subscribe((res: CalibratesRange[]) => {
          this.calibrateRanges = res;
        });
      }
     
    }
  
  }

  onOkClick(): void {
    const errs: string[] = [];

    if (this.data.calibration.equipmentType != this.isParticleCounter) {
      if (this.data.calibration.unitType == null) {
        errs.push(this.translate.instant('quoteEdit.dialog.calibrates.unitType.error') as string);
      }
      if (this.data.calibration.variableType == null) {
          errs.push(this.translate.instant('quoteEdit.dialog.calibrates.variable.error') as string);
      }
      // if (this.data.calibration.calibrationRange == null) {
      //     errs.push(this.translate.instant('quoteEdit.dialog.calibrates.range.error') as string);
      // }
      if (this.data.calibration.calibrationRange == null && this.data.calibration.preassureRange == null) {
        errs.push(this.translate.instant('quoteEdit.dialog.calibrates.range.error') as string);
    }
      if (this.data.calibration.calibrationSite == null) {
          errs.push(this.translate.instant('quoteEdit.dialog.calibrates.site.error') as string);
      }
      if(this.data.calibration.serie == null && this.data.calibration.indicative == null) {
        if (this.data.calibration.serie == null) {
          errs.push(this.translate.instant('quoteEdit.general.nserie.error') as string);
        } else if (this.data.calibration.indicative == null) {
          errs.push(this.translate.instant('quoteEdit.general.nidentificative.error') as string);
        }
        
      }
      if(this.data.calibration.equipmentType === this.EQUIPMENT_TYPE_TEXTURE_ANALYZER){
        errs.length = 0;
      }
    } else {
      if (!this.data.calibration.particleCounterType) {
        errs.push(this.translate.instant('quoteEdit.dialog.calibrates.particleType.error') as string);
      }
    }
    if (this.data.calibration.outsourced || this.isOutsourcing) {
        if (this.data.calibration.provider == null) {
            errs.push(this.translate.instant('quoteEdit.dialog.calibrates.costProvider.error') as string);
        }

        if (this.data.calibration.costProvider == null) {
          errs.push(this.translate.instant('quoteEdit.dialog.calibrates.costProvider.error') as string);
      }
    }


    if (errs.length === 0) {
        this.dialogRef.close(this.data.calibration);
    } else {
        const error = errs.join('\n');
        this.snackBarService.sendError(error);
    }

}
  onNoClick() {
    this.dialogRef.close();
  }

  onVariableTypeChange(): void {
    this.calibrateRanges = [];
    this.unitVariableService.findVariableIdEqId(this.data.calibration.variableType, this.data.calibration.equipmentType).subscribe((res: UnitVariable[])=> {
      this.unitVariableTypes = res;
      if (res?.length === 1) {
        this.data.calibration.unitType = null;
        this.data.calibration.unitType = res[0].unitId;

        this.calibrateRangeService.findAllByVariableAndEquipment(this.data.calibration.variableType, this.data.calibration.equipmentType, this.data.calibration.unitType).subscribe((res: CalibratesRange[]) => {
          this.calibrateRanges = res;
          if (res?.length === 1) {
            this.data.calibration.preassureRange = null;
            this.data.calibration.calibrationRange = null;
            this.data.calibration.calibrationRange = res[0].id;
            this.onEquipmentIdVariableIdUnitId();
          } else {
            const result = res.find(t => t.id === this.data.calibration?.calibrationRange);
            if (!result) {
              this.data.calibration.preassureRange = null;
              this.data.calibration.calibrationRange = null;
            }
          }
        });
      } else {
        const result = res.find(t => t.unitId === this.data.calibration?.unitType);
        if (!result) {
          this.data.calibration.unitType = null;
        }
      }
    });
  } 
  selectedEquipmentTypeAllowsInput() {
    const allowedEquipmentTypeIds = [27, 28, 29, 30, 31, 32, 33, 80, 82, 83, 85];
    return allowedEquipmentTypeIds.includes(this.data.calibration.equipmentType);
}

  loadVariableTypesByEquipmentType(): void {
    this.variableTypeService.getAllVariableTypeByEquipment(this.data.calibration.equipmentType)
        .subscribe((res: VariableType[]) => {
            this.selectedEquipmentVariables = res;
            if (this.data.calibration.variableType) {
              const existsVariable = this.selectedEquipmentVariables?.find(e => e.id === this.data.calibration.variableType);
              if (!existsVariable) {
                this.data.calibration.variableType = null;
              }
            }
            if (res?.length === 1) {
              this.data.calibration.variableType = res[0].id;
              this.onVariableTypeChange();
            } else if (this.data.isEdit && this.data.calibration?.variableType) {
              this.onVariableTypeChange();
            }
        });
  }

  filterEquipmentType(value: string) {
    const arrayEquipment = _.cloneDeep(this.equipmentTypesFiltered);
    if (!value) {
      this.equipmentTypes = arrayEquipment;
      return;
    }
  
    this.equipmentTypes = arrayEquipment.filter((equipmentType) => {
        const translate: string = this.translate.instant(`equipmentType.${equipmentType.translate}`);
        return translate.toLowerCase().includes(value.toLowerCase())
      }
    );
  }

  onProviderQuoteEmitter(event: any): void {
    this.data.calibration.provider = event;
    this.data.calibration.costProvider = event?.cost;
    this.data.calibration.saleProvider = event?.sale;
  }

  onEquipmentIdVariableIdUnitId(): void {
    this.calibrateTimeConfigService.findALlByEquipmentIdVariableIdUnitId(this.data.calibration.equipmentType, this.data.calibration.variableType, this.data.calibration.unitType, this.data.calibration.calibrationRange)
        .subscribe((res: CalibrateTimeConfig) => {
            this.calibrationTimeConfig = res;

            if (this.calibrationTimeConfig) {
              this.isOutsourcing = this.calibrationTimeConfig.outsourcing; 
            }
        });
  }


}