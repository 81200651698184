import * as _ from 'lodash-es';

import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Area } from 'src/app/model/area';
import { AreaPrice } from 'src/app/model/areaPrice';
import { AuditType } from 'src/app/model/auditType';
import { Client } from 'src/app/model/client';
import { InterArea } from 'src/app/model/interArea';
import { InterAreaResult } from 'src/app/model/interAreaResult';
import { QuoteDTO } from 'src/app/model/quote';
import { AuditTypeService } from 'src/app/service/audit-type.service';
import { ClientService } from 'src/app/service/client.service';
import { DietTravelService } from 'src/app/service/dietTravel.service';
import { LoadingService } from 'src/app/service/loading.service';
import { QuoteService } from 'src/app/service/quote.service';
import { TestTypeEquipmentConfigService } from 'src/app/service/testTypeEquipmentConfig.service';
import { NumberUtils } from 'src/app/utils/numberUtils';
import { Constants } from 'src/app/utils/constants';
import { Subscription } from 'rxjs';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-quote-detail',
  templateUrl: './quote-detail.component.html',
  styleUrls: ['./quote-detail.component.scss'],
})
export class QuoteDetailComponent implements OnInit, AfterViewInit {

  @Input() quote: QuoteDTO = new QuoteDTO();
  @ViewChild('divCost') divCost: ElementRef;
  @ViewChild('divTotalAmount') divTotalAmount: ElementRef;
  @ViewChild('divTotalgainsPercentaje') divTotalgainsPercentaje: ElementRef;

  isConsultancy : boolean = false;
  isThermal: boolean = false;
  isAir: boolean = false;
  isCalibration: boolean = false;
  hasProfile: boolean = false;
  hasQualification: boolean = false;
  hasQualiThermal: boolean = false;

  areas: AreaPrice[] = [];
  interArea: InterArea[] = [];
  client: Client = new Client();
  dietAmount = 0;
  results: InterAreaResult[] = [];
  auditTypes: AuditType[];

  productionCost: number;
  gainsPercentaje: number;
  totalCourses = 0;
  totalCostThermal = 0;
  totalHourThermal = 0;
  totalSaleThermal = 0;
  totalSaleConsultorThe = 0;
  totalCostConsultorThe = 0;
  totalTimeConsultorThe = 0;
  totalPriceProfile = 0;
  totalCostProfile = 0;
  totalTimeProfile = 0;

  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService,
    private dietTravelService: DietTravelService,
    private quoteService: QuoteService,
    private testTypeEquipmentConfigService: TestTypeEquipmentConfigService,
    public loader: LoadingService,
    private auditTypeService: AuditTypeService,
    private renderer:Renderer2,
    private currencyPipe: CurrencyPipe
  ) { }

  ngOnInit(): void {
    this.loader.show();
    this.results.push(new InterAreaResult());
    this.route.queryParams.subscribe(params => {
      let id = params[Constants.QUOTE_ID] as number;
        // Recuperamos tipos de equipos
      if (this.quote != null){
        if (!this.quote.noScope) {
          this.isConsultancy = this.quote.idType == 4 || this.quote.idType === 5;
          this.isThermal = this.quote.idType === 2 || this.quote.idType === 5;
          this.isAir = this.quote.idType === 1 || this.quote.idType === 5;
          this.isCalibration = this.quote.idType === 3 || this.quote.idType === 5;
          this.testTypeEquipmentConfigService.totalCostTestPerArea(id).subscribe((res: InterAreaResult[]) => {
            this.results = res;
            this.results.sort((a, b) => a.id < b.id ? -1 : +(a.id > b.id));

            this.results.forEach(result => {
              result.areas.forEach(area => {
                //area.totalCostTestPerArea += this.getTotalSaleConsultor(area);
                area.totalDays = this.getAreaWorkday(area.totalTimePerArea);
                if (this.isConsultancy) {
                  area.totalCostConsultor = Math.round(this.getTotalCostConsultor(area) * 100) / 100;
                  area.totalTimeConsultor = this.getTimeConsultor(area);
                  area.totalSaleConsultor = Math.round(this.getTotalSaleConsultor(area) * 100) / 100;
                }
              });
              this.updateHtml();
              this.loader.hide();
            });
            if (this.isConsultancy) {
              // this.totalCourses = this.getCoursesTotal();
              this.quote.workcenters.find(it => {
                  if(it.equipmentThermal != null && it.equipmentThermal?.length > 0) {
                      this.isThermal = true;
                  }

                  if (it.consultancyProfiles && it.consultancyProfiles.length > 0) {
                    this.hasProfile = true;
                  }
              });
            }

            this.productionCost = this.getTotalProductionPrice();
            this.gainsPercentaje = this.getPercentajeGains();
          });
          this.getTotalAmountDietGroupByQuote();
        }
        if (this.quote.idClient != null) {
          this.loader.hide();
        }

        this.auditTypeService.findAll().subscribe((res: AuditType[]) => {
          this.auditTypes = res;
        });

      }
    });
  }
  
  ngAfterViewInit(): void {
      this.updateHtml();
  }

  getTotalProductionPrice(): number {
    let price = 0;
    this.results.forEach(result => {
      result.areas?.forEach(area => {
        price += area.costProduction;
      })
    });
    return price;
  }

  getPercentajeGains(): number {
    const total = this.quote.totalAmount- this.getTotalAmountDietGroupByQuote();
    let price = (total-this.quote.totalCost)*100/total;    
    return NumberUtils.round(price,2);
  }

  getCoursesTotal(idWc: number): number {
    let price = 0;
    this.quote.workcenters?.filter(e => e.id === idWc)?.forEach(wc => {
        wc.courses?.forEach(course => {
          price += course.price;
        });
    });
    return price;
  }

  getHours(minutes: number): number {
    return Math.ceil(NumberUtils.round((minutes/60)))
  }

  private getAreaWorkday(hours: number): number {
    return NumberUtils.round((hours/60)/8,2);
  }

  private getTotalAmountDietGroupByQuote(): number {
    this.dietTravelService.totalAmountDietGroupByQuote(this.quote.id).subscribe(res => {
      this.dietAmount = res.sumDiets;
    });
    return this.dietAmount ? this.dietAmount : 0;
  }

  private getTotalCostConsultor(area: InterArea): number {
    let cost = 0;
    let areaFinded: Area;
    this.quote.workcenters.forEach(wc => {
      areaFinded = wc.areas.find(a => a.id === area.id);

      if(areaFinded != null){
        areaFinded.equipments?.forEach(eq => {
          eq.qualifications?.forEach(quali => {
            cost += quali.cost;
          });
        });
        cost += areaFinded.totalConsultancyCostRoom;
  
        areaFinded.compressedGases?.forEach( gas => {
          gas.qualifications?.forEach( quali => {
            cost += quali.cost;
          })
        });
      }
    });
    return cost;
  }

  private getTotalSaleConsultor(area: InterArea): number {
    let price = 0;
    let areaFinded: Area;
    this.quote.workcenters.forEach(wc => {
      areaFinded = wc.areas.find(a => a.id === area.id);

      if(areaFinded != null){
        areaFinded.equipments?.forEach( eq => {
          eq.qualifications?.forEach( quali => {
            price += quali.price;
          })
        });
        price += areaFinded.totalConsultancyRoom;
        
        areaFinded?.compressedGases?.forEach( gas => {
          gas.qualifications.forEach( quali => {
            price += quali.price;
          })
        });
      }
    });
    
    return price;
  }

  private getTimeConsultor(area: InterArea): number {
    let time = 0;
    let areaFinded: Area;
    this.quote.workcenters.forEach(wc => {
      areaFinded = wc.areas.find(a => a.id === area.id);

      if (areaFinded) {
        areaFinded.equipments?.forEach( eq => {
          eq?.qualifications?.forEach( quali => {
            time += quali.hours;
          })
        });
        time += areaFinded.totalConsultancyTimeRoom;
    
        areaFinded.compressedGases?.forEach( gas => {
          gas?.qualifications?.forEach( quali => {
            time += quali.hours;
          })
        });
      }
    });
    
    return time;
  }

  showAuditName(idType: number):string {
    let name = '';
    this.auditTypes.forEach(e => {
      if (e.id === idType) {
        name = e.translate;
      }
    });
    return 'quoteEdit.general.audit.'.concat(name);
  }

  updateHtml() {
    if (this.divTotalAmount?.nativeElement) {
      this.renderer.setProperty(this.divTotalAmount?.nativeElement, 'innerHTML', this.currencyPipe.transform(this.quote.totalAmount, 'EUR'));
    }
    if (this.divCost?.nativeElement) {
      this.renderer.setProperty(this.divCost?.nativeElement, 'innerHTML', this.currencyPipe.transform(this.quote.totalCost, 'EUR'));
    }
    if (this.divTotalgainsPercentaje?.nativeElement) {
      this.renderer.setProperty(this.divTotalgainsPercentaje?.nativeElement, 'innerHTML',this.getPercentajeGains());
    }
  }
}


