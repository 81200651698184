import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Provider } from 'src/app/model/provider';
import { ProviderQuote } from 'src/app/model/providerQuote';
import { ProviderService } from 'src/app/service/provider.service';

@Component({
  selector: 'app-quote-edit-outsources',
  templateUrl: './quote-edit-outsources.component.html'
})
export class QuoteEditOutsourcesComponent implements OnInit {

  @Input() providerQuote: ProviderQuote;
  @Output() providerQuoteEmitter = new EventEmitter<ProviderQuote>();

  providers: Provider[] = [];
  providersFiltered: Provider[] = [];

  constructor(
    private providerService: ProviderService
  ) { 
    
  }

  ngOnInit(): void {
    this.providerService.findAll().subscribe((res: Provider[]) => {
      this.providers = res;
      this.providersFiltered = this.providers.slice();
    });
    if (this.providerQuote == null) {
      this.providerQuote = new ProviderQuote();
    }
  }

  onProviderQuoteEmitter(event: any): void {
    this.providerQuoteEmitter.emit({
      id: this.providerQuote.id,
      cost: this.providerQuote.cost,
      sale: this.providerQuote.sale
    });
  }

}
