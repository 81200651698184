import { GenericFilter } from "./genericClass";

export class Client {

    id: number;
    name: string;
    address: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
    nameBusinessGroup: string;
    idQuote: number;
    cif: string;
    active: boolean;
    special: boolean;
    idBusinessGroup: number;
    idPaymentMethod: number;
    contactName : string;
    phone: number;
    reponsabilityCenter: string;
    email :string;
    workAddress: string;
    clientContact: ClientContact[] = [];
}

export class ClientContact {

    id: number;

    fullName: string;
    position: string;
    email: string;
    phone: string;

    idClient: number;
    clientName: string;

}

export class ClientFilter extends GenericFilter {

    name: string;
    address: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
    nameBusinessGroup: string;
}
export class ClientQualiAPI {
 
    id: number;
    activo: boolean;
    nombre: string;
    fecha_creacion: Date;
    fecha_modificacion: Date;
    cif: string;
    email_contacto: string;
    direccion: string;
    localidad: string;
    region: string;
    provincia: string;
    pais: string;
    fk_cliente_crm: string;
    fk_cliente_erp: string;
    fk_cliente_qloud : string;
    codigo_postal: string;
}

export class ClientNavision {
 
    id: number;
    activo: boolean;
    name: string;
    cliid: string;
    fecha_creacion: Date;
    fecha_modificacion: Date;
    cif: string;
    email_contacto: string;
    direccion: string;
    localidad: string;
    region: string;
    provincia: string;
    pais: string;
    fk_cliente_crm: string;
    fk_cliente_erp: string;
    fk_cliente_qloud : string;
    codigo_postal: string;
     name1: string;
     name2: string;
     address_2: string;
     telefono: string;
     codigoPostal: string;
     homePage: string;
     salespersonCode: string;
     billToCustomerNo: string;
     paymentTermsCode: string;
     paymentMethodCode: string;
     globalDimension1Code: string;
     globalDimension2Code: string;
     customerPostingGroup: string;
     genBusPostingGroup: string;
     vatBusPostingGroup: string;
     currencyCode: string;
     languageCode: string;
     responsibilityCenter: string;
     Departamento: string;
     customerClassification: string;
     correoFactElectrónica: string;
     netChangeLCY: string;
     salesLCY: string;
     globalDimension1Filter: string;
     globalDimension2Filter: string;
     dateFilter: string;
     currencyFilter: string;
     noEnviarCorreoReclamacion: string;
}

export class ClientNavisionShort {
    id: string;
    activo;
    cliid: string;
    idCrm: string;
    nombre: string;
    cif: string;
    direccion: string;
    localidad: string;
    provincia: string;
    centrosdetrabajo: ClientWorkCenter[];
}

export class ClientWorkCenter {
    id: string;
    activo;
    cliid: string;
    direccion: string;
    nombre: string;
    localidad: string;
    cif: string;
}
