import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class WorkTypeService {

    constructor(private http: HttpClient) {
    }


    findAllQualiApi(): Observable<any> {
        const url = environment.quotit + '/worktype_qualiapi/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}
