 <h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
  <div mat-dialog-content class="air-theme">

    <div fxLayout="row" class="flexwrap">
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
            <input matInput [(ngModel)]="data.orderNum" name="amount" type="text"
              placeholder="{{ 'quoteEdit.dialog.quotewon.ordernum.label' | translate }}">
          </mat-form-field>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100%" >
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'quoteEdit.dialog.quotewon.manager.label' | translate }}
          </mat-label>
          <mat-select #sellerSelect required name="seller"
              [(ngModel)]="data.projectManager">
              <mat-select-filter [displayMember]="'Description'"
                  [array]="sellers" (filteredReturn)="sellersFiltered =$event"
                  [noResultsMessage]="'common.noResults' | translate"
                  [placeholder]="'quoteEdit.dialog.quotewon.manager.label' | translate">
              </mat-select-filter>
              <mat-option *ngFor="let itemCommercial of sellersFiltered"
                  [value]="itemCommercial.Code">
                  {{ itemCommercial.Description }}
              </mat-option>
          </mat-select>
          <mat-error>
              {{ 'quoteEdit.general.commercial.error' | translate }}
          </mat-error>
      </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" class="flexwrap">
      <div fxFlex="100" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
            <textarea rows="5" matInput [(ngModel)]="data.others" name="amount" type="textarea"
              placeholder="{{ 'quoteEdit.dialog.quotewon.notes.label' | translate }}"></textarea>
          </mat-form-field>
      </div>
    </div>



  </div> 
  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>