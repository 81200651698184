import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PipeDriveCardOwners } from 'src/app/model/pipeDriveCardOwner';
import { PipeDriveCardOwnerQualiApiService } from 'src/app/service/pipeDriveCardOwnerQualiApi.service';

@Component({
  selector: 'app-quote-edit-dialog-comercial-pipe',
  templateUrl: './quote-edit-dialog-comercial-pipe.component.html'
})
export class QuoteEditDialogComercialPipeComponent implements OnInit {

  tituloDialog: string;
  ownersFiltered: PipeDriveCardOwners[];
  owners: PipeDriveCardOwners[];
  cardOwner: string;

  constructor(
    private commercialService: PipeDriveCardOwnerQualiApiService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<QuoteEditDialogComercialPipeComponent>
  ) { }

  ngOnInit(): void {
    this.tituloDialog = this.translate.instant('quoteEdit.dialog.cardOwners.title') as string;
        this.commercialService.findAllNavisionDTO().subscribe((res: PipeDriveCardOwners[]) => {
          this.owners = res;
          this.ownersFiltered = this.owners.slice();
        });

  }

  onOkClick(): void {
    const errs: string[] = [];

    if (this.cardOwner == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.comercial.cardOwner.error') as string);
    }
    this.dialogRef.close(this.cardOwner);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
