
export class Diet {
    id: number;
    idDietTravel: number;
    idQuote: number;
    amount :number;
    dietCodeNav: string;
    departmentCodeNav: string;
    resourceCodeNav: string;
}


export class WorkType {
    
    odataEtag: string;
    Code: string;
    Description: string;
    etag: string;

}