
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MSAL_INSTANCE, MsalModule, MsalService } from '@azure/msal-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ClientService } from './service/client.service';
import { CourseEditModule } from './components/course-edit/course-edit.module';
import { CourseListModule } from './components/course-list/course-list.module';
import { CourseService } from './service/course.service';
import { DrawerService } from './service/drawer.service';
import { EditUserModule } from './components/user-edit/edit-user.module';
import { EquipmentTypeService } from './service/equipmentType.service';
import { InterceptorsModule } from './interceptors/interceptors.module';
import { LoginModule } from './components/login/login.module';
import { LoginService } from './service/login.service';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PipesModule } from './pipes/pipes.module';
import { ProviderService } from './service/provider.service';
import { QuoteEditModule } from './components/quote-edit/quote-edit.module';
import { QuoteListModule } from './components/quote-list/quote-list.module';
import { QuoteService } from './service/quote.service';
import { SharedModule } from './components/shared/shared.module';
import { SnackBarService } from './service/snackBar.service';
import { SpinnerService } from './service/spinner.service';
import { TestTypeEquipmentConfigService } from './service/testTypeEquipmentConfig.service';
import { TestTypeService } from './service/testType.service';
import { ToastrModule } from 'ngx-toastr';
import { TranslateUniversalLoader } from './utils/translateLoader';
import { TranslationService } from './service/translation.service';
import { UserService } from './service/user.service';
import { environment } from 'src/environments/environment';
import { DietTravelService } from './service/dietTravel.service';
import { ClientListModule } from './components/client-list/client-list.module';
import { ContactService } from './service/contactService';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CAcceptanceCriteriaService } from './service/cAcceptanceCriteria.service';
import { QuoteDetailModule } from './components/quote-detail/quote-detail.module';
import { PaymentMethodService } from './service/paymentMethod.service';
import { CommercialService } from './service/commercial.service';
import { UserListModule } from './components/user-list/user-list.module';
import { TimeZoneService } from './service/timezone.service';
import { ComercialListModule } from './components/comercial-list/comercial-list.module';
import { QuoteTypeService } from './service/quoteType.service';
import { ProfileConsultancyTypeService } from './service/ProfileConsultancyType.service';
import { Area } from './model/area';
import { ConsultancyTypeService } from './service/consultancyType.service';
import { EquipmentTypeListModule } from './components/equipmentType-list/equipmentType-list.module';
import { CourseDiscountService } from './service/courseDiscount.service';
import { CourseModalityService } from './service/courseModality.service';
import { CalibratesRangeService } from './service/calibratesRange.service';
import { CalibrationSiteService } from './service/calibrationSite.service';
import { UnitService } from './service/unit.service';
import { VariableTypeService } from './service/variableType.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { ResponsabilityCenterService } from './service/responsabilityCenter.service';
import { TestTypeThermalEquipmentConfigService } from './service/testTypeThermalEquipmentConfig.service';
import { AttachmentService } from './service/attachment.service';
import { CourseDifficultyService } from './service/courseDifficulty.service';
import { RoleService } from './service/role.service';
import { PaymentTermService } from './service/paymentTerm.servic';
import { WorkTypeService } from './service/workType.service';
import { PaymentModeService } from './service/paymentMode.service';
import { ResourceQualiApiService } from './service/resourceQualiApi.service';
import { DepartementService } from './service/departement.service';
import { ProjectSubTypeQualiApiService } from './service/projectSubType.service';
import { ProjectTypeQualiApiService } from './service/projectType.service';
import { PipeDriveDealsQualiApiService } from './service/pipeDriveDealsQualiApi.service';
import { PipeDriveCardOwnerQualiApiService } from './service/pipeDriveCardOwnerQualiApi.service';


export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalClientId,
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: environment.postLogoutRedirectUri,
      authority: `https://login.microsoftonline.com/${environment.msalTenantId}`
    }
  });
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    MatButtonModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ClientListModule,
    CourseListModule,
    CourseEditModule,
    EditUserModule,
    HttpClientModule,
    InterceptorsModule,
    QuoteEditModule,
    QuoteListModule,
    QuoteDetailModule,
    UserListModule,
    ComercialListModule,
    EquipmentTypeListModule,
    DashboardModule,

    /* Translate */
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      extendedTimeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
      closeButton: true
    }),

    NgxSpinnerModule,

    /* Login con microsoft */
    MsalModule,

    /* Custom modules */
    SharedModule, LoginModule, PipesModule
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },

    ClientService, CAcceptanceCriteriaService, DrawerService, DietTravelService, LoginService, SnackBarService, SpinnerService, TranslationService, RoleService, UserService, QuoteService,

    MsalService, ContactService, CourseService, TestTypeEquipmentConfigService, ProviderService, EquipmentTypeService, CommercialService, TimeZoneService,

    TestTypeService, PaymentMethodService, QuoteTypeService, ProfileConsultancyTypeService, Area, ConsultancyTypeService, CourseDiscountService, CourseModalityService, TestTypeThermalEquipmentConfigService,
    CalibratesRangeService, CalibrationSiteService, VariableTypeService, UnitService, ResponsabilityCenterService, AttachmentService, CourseModalityService, CourseDifficultyService, 
    PaymentTermService, WorkTypeService, PaymentModeService, ResourceQualiApiService, DepartementService, ProjectSubTypeQualiApiService, ProjectTypeQualiApiService, PipeDriveDealsQualiApiService, 
    PipeDriveCardOwnerQualiApiService, 
    /* https://stackoverflow.com/questions/35284988/angular-2-404-error-occur-when-i-refresh-through-the-browser */
    { provide: LocationStrategy, useClass: HashLocationStrategy },

    // https://stackoverflow.com/a/60275759/3693956
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient): TranslateLoader {
  return new TranslateUniversalLoader(http, 'assets/i18n/', '.properties');
}
