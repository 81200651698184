import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DepartementNavision } from 'src/app/model/deparmenentNavision';
import { Diet, WorkType } from 'src/app/model/diet';
import { DietTravel } from 'src/app/model/dietTravel';
import { ResourceQualiApi } from 'src/app/model/resourceQualiApi';
import { DepartementService } from 'src/app/service/departement.service';
import { ResourceQualiApiService } from 'src/app/service/resourceQualiApi.service';
import { WorkTypeService } from 'src/app/service/workType.service';

export interface DialogDataArea {
  diet: Diet;
  isEdit: boolean;
}

@Component({
  selector: 'app-quote-edit-dialog-diet',
  templateUrl: './quote-edit-dialog-diet.component.html'
})
export class QuoteEditDialogDietComponent {

  tituloDialog: string;
  dietTravels: WorkType[];
  dietTravelsFiltered: WorkType[];
  workTypeSelected: WorkType;
  departements: DepartementNavision[];
  resources: ResourceQualiApi[];
  departementsFiltered: DepartementNavision[];
  resourcesFiltered: ResourceQualiApi[];

  constructor(
    private dietTravelService: WorkTypeService,
    private resourceQualiApiService: ResourceQualiApiService,
    private departementService: DepartementService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<QuoteEditDialogDietComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataArea) {

    if (data.isEdit) {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.diet.title.edit', { name: data.diet.idDietTravel }) as string;
    } else {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.diet.title.create') as string;
    }

    this.departementService.findAllDepartementNavision().subscribe((res: DepartementNavision[]) => {
      this.departements = res;
      this.departementsFiltered = this.departements?.slice();
    });
    
    this.resourceQualiApiService.findAll().subscribe((res: ResourceQualiApi[]) => {
      this.resources = res;
      this.resourcesFiltered = this.resources?.slice();
    });

    this.dietTravelService.findAllQualiApi().subscribe((res: WorkType[]) => {
      this.dietTravels = res;
      this.dietTravelsFiltered = this.dietTravels?.slice();
    });
  }

  onOkClick(): void {
    const errs: string[] = [];

    if (this.data.diet.idDietTravel == null && this.data.diet.dietCodeNav == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
    }
    if (this.data.diet.amount == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.area.name.error') as string);
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.diet);
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
