import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { ClientWorkCenter } from 'src/app/model/client';

import { Workcenter } from 'src/app/model/workCenter';


export interface DialogDataWorkcenter {
  workcenter:Workcenter;
  isEdit: boolean;
  centers: ClientWorkCenter[];
}

@Component({
  selector: 'app-quote-edit-dialog-workcenter',
  templateUrl: './quote-edit-dialog-workcenter.component.html'
})
export class QuoteEditDialogWorkcenterComponent {

  tituloDialog: string;


  constructor(
    private translate: TranslateService,
    private dialogRef: MatDialogRef<QuoteEditDialogWorkcenterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataWorkcenter) {
    if (data.isEdit) {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.workcenter.title.edit', { name: data.workcenter.name }) as string;
    } else {
      this.tituloDialog = this.translate.instant('quoteEdit.dialog.workcenter.title.create') as string;
    }

  }

  onOkClick(): void {
    const errs: string[] = [];

    if (this.data.workcenter.name == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.workcenter.name.error') as string);
    }
    if (this.data.workcenter.address == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.workcenter.address.error') as string);
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data.workcenter);
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  onCenterChange(event: MatSelectChange) {
    let center: ClientWorkCenter = this.data.centers.find(c => c.nombre === event.value);
    this.data.workcenter.address = center.direccion;
  }
}
