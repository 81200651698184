import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SellerNavision } from 'src/app/model/commercial';
import { PipeDriveCardOwners } from 'src/app/model/pipeDriveCardOwner';
import { QuoteWonData } from 'src/app/model/quoteWonData';
import { CommercialService } from 'src/app/service/commercial.service';
import { PipeDriveCardOwnerQualiApiService } from 'src/app/service/pipeDriveCardOwnerQualiApi.service';


@Component({
  selector: 'app-quote-edit-dialog-quotewon',
  templateUrl: './quote-edit-dialog-quotewon.component.html'
})
export class QuoteEditDialogQuotewonComponent implements OnInit {

  tituloDialog: string;
  sellersFiltered: SellerNavision[];
  sellers: SellerNavision[];
  pipeDriveCardOwnersFiltered: PipeDriveCardOwners[];
  pipeDriveCardOwners: PipeDriveCardOwners[];
  data: QuoteWonData = new QuoteWonData();

  constructor(

    private commercialService: CommercialService,
    private pipeDriveCardOwnerQualiApiService: PipeDriveCardOwnerQualiApiService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<QuoteEditDialogQuotewonComponent>
  ) { }

  ngOnInit(): void {
    this.tituloDialog = this.translate.instant('quoteEdit.dialog.quotewon.title') as string;
    this.commercialService.findAllSellerNavision().subscribe((res: SellerNavision[]) => {
      this.sellers = res;
      this.sellersFiltered = this.sellers.slice();
    });
    this.pipeDriveCardOwnerQualiApiService.findAllNavisionDTO().subscribe((res:PipeDriveCardOwners[])=>{
      this.pipeDriveCardOwners = res;
      this.pipeDriveCardOwnersFiltered = this.pipeDriveCardOwners.slice();
    });
  }

  onOkClick(): void {
    const errs: string[] = [];

    if (this.data.projectManager == null) {
      errs.push(this.translate.instant('quoteEdit.dialog.quotewon.manager.error') as string);
    }

    if (errs.length === 0) {
      this.dialogRef.close(this.data);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
