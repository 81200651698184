export class PaymentMode {
    id: number;
    name: string;
}


export class PaymentModeNavision {
    id: number;
    reference: number;
}

