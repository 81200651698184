import { Client } from "./client";
import { Contact } from "./contact";
import { Course } from "./course";
import { Diet } from "./diet";
import { GenericAttachment, GenericFilter } from "./genericClass";
import { User } from "./user";
import { Workcenter } from "./workCenter"


export class QuoteDTO {
    /** idQuote*/
    id: number;
    /** Relación con la entidad User  */
    idUser: number;

    idType: number;
    
    userfullName: string;
    /** Relación con la entidad Client */
    idClient: number;
    /** Description Project*/
    descriptionProject: string;
    /** id Client Contact */
    idClientContact: number;
    /** Scope */
    scope: string;
    /** Description Service*/
    descriptionService: string;
    /** Document code */
    quoteNumber: string;
    /** Project number */
    projectNumber: string;
    /** Discount Increase */
    discountIncrease: number;
    /** CheckBox 'with Protocol' */
    withProtocol: boolean;
    /** CheckBox 'recalculate' */
    recalculate: boolean;
    /** CheckBox 'implant' */
    implant: boolean;
    /** CheckBox 'version Control' */
    versionControl: boolean;
    /** CheckBox 'work Center' */
    workCenter: boolean;
    /** CheckBox 'show Diets' */
    showDiets: boolean;
    /**CheckBox per journal */
    journal: boolean;
    numberOfJournals: number;
    active: boolean;
    exceptionalWorks: boolean;
    totalAmount: number;
    totalCost: number;
    totalTime: number;
    totalAmountThermal: number;
    totalTimeThermal: number;
    totalCostThermal: number;
    totalAmountAir: number;
    totalTimeAir: number;
    totalCostAir: number;
    totalTimeCalibration: number;
    totalAmountCalibration: number;
    totalCostCalibration: number;

    totalAmountConsultancy: number;
    totalTimeConsultancy: number;
    totalCostConsultancy: number;
    
    idPaymentMethod: number;
    codePaymentMethod: string;
    codePaymentTerm: string;
    workcenters: Workcenter[] = [];
    courses: Course[];

    exclusiveUrgentService: boolean;

    idStatus: number;

    translateStatus: string;

    idCommercial: number;
    commercialCodeNav: number;

    idTimeZone: number;

    idResponsabilityCenter: number;

    valueDiscountIncrease: string;
    numVersion: string;
    idVersionControl: number;
    dateWonLost: Date;
    dateSend: Date;
    information: String = "";
    noScope: boolean;
    workday: number;
    quoteAttachments: QuoteAttachment[];
    idCenterNav: string;
    newVersionReason: string;
    paymentPercentage: number;
    offerCodeNav: string;
    offerTypeNav: string;
    projectTypeNav: string;
    projectSubTypeNav: string;
    projectNotes: string;
    orderNum: string;
    projectManager: string;
    pipeDriveDeal: string;
    pipeDriveCommercial: string;
    nombreOfertaPIPE: string;
}

export class Quote {
    id: number;
    quoteNumber: string;
    projectNumber: string;
    idStatus: number;
    active: boolean;
    idClient: number;
    idContact: number;
    idType: number;
    clientName: string
    commercialUserFullName: string;

    client: Client;
    contact: Contact;
    commercial: User;

    registrationUser: string;
    registrationDate: Date;
    modificationUser: string;
    modificationDate: Date;
    descriptionService: string;
    descriptionProject: string;
    scope: string;
    idPaymentMethod: number;
    discountIncrease: number;
    withProtocol: boolean;
    recalculate: boolean;
    versionControl: boolean;
    workcenter: boolean;
    implant: boolean;
    showDiets: boolean;
    target: string;
    workcenters: Workcenter[] = [];
    diets: Diet[] = [];
    others: string;
    numVersion: number;
    cofferStatus: any;
    dateWonLost: Date;
    dateSend: Date;
    information: String;
    noScope: boolean;
    contactName: string;
    newVersionReason: string;
    projectNotes: string;
    orderNum: string;
    projectManager: string;
}

export class QuoteFilter extends GenericFilter {

    quoteNumber: string;
    //area: 
    idStatus: number;
    descriptionProject: string;
    //contact:
    commercial: string;
    name: string;
    registrationDate: Date;
    registrationUser: string;
    projectNumber: string;
    idClient: number;
    active: boolean;
    modificationDate: Date;
    cols: string[];
    idType: number;
    totalAmount: number;
    idContact: number;
    idComercial: number;
    idUser: number;
}

export class QuoteType {

    id: number;
    translate: string;
}

export class QuoteTypeNav {
    Code: string;
    Description: string;
}

export class QuoteAttachment extends GenericAttachment {
    idQuote: number;
}

export class QuoteAttachmentFilter extends GenericFilter {
}

export class QuoteAttachmentNoScope {
    type: number;
    file: File;
}
