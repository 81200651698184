<form>
    <div class="container">
        <div class="row">
        <div div fxFlex="100" fxFlex.lt-md="100%">
            <div fxFlex="25" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <mat-label> {{'quoteEdit.general.consultory.profile'|
                        translate}}
                    </mat-label>
                    <mat-select required [(ngModel)]="data.profile.resourceCodeNav"
                        (selectionChange)="onConsultorytypeChance($event)"
                        name="profileType">
                        <mat-option
                            *ngFor="let itemProfile of resources"
                            [value]="itemProfile.No">
                            {{itemProfile.Name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="25" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput type="number" [(ngModel)]="data.profile.hours"
                        name="hours" min="0"
                        placeholder="{{ 'quoteEdit.general.consultory.hours' | translate}}">
                </mat-form-field>
            </div>
            <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="showClosedPrice">
                <mat-form-field class="campoFormulario">
                    <input matInput required type="number" name="closedPrice"
                        [(ngModel)]="data.profile.price" min="0"
                        placeholder="{{ 'quoteEdit.general.consultory.closedprice' | translate}}">
                </mat-form-field>
            </div>
            <div fxFlex="25" fxFlex.lt-md="100%" div fxLayout="row"
                fxLayoutAlign="start center">
                <mat-checkbox
                    matTooltip="{{ 'quoteEdit.general.button.implant' | translate }}"
                    name="quoteImplant" [(ngModel)]="data.profile.implant">
                    {{ 'quoteEdit.general.button.implant' | translate }}
                </mat-checkbox>
            </div>
        </div>
    </div>
    </div>
  
    <div mat-dialog-actions class="air-theme">
      <div fxFlex></div>
      <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
      <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>
  
  </form>