import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ResponsabilityCenterService {

    constructor(private http: HttpClient) { }

    findAllCenter(): Observable<any> {
        const url = environment.quotit + '/responsabilitycenter/all' ;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findByTimeZoneId(timeZoneId: number): Observable<any> {
        const url = environment.quotit + `/responsabilitycenter/byTimeZoneId/${timeZoneId}`;
        return this.http.get(url);
      }
    
      findAllQualiApi(): Observable<any> {
        const url = environment.quotit + '/respocenter_qualiapi/';

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
}




