import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ComercialFilter } from '../model/commercial';

@Injectable()
export class CommercialService {

    constructor(private http: HttpClient) { }

    findAllCommercial(): Observable<any> {
        const url = environment.quotit + `/commercial/findAllCommercial`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

    findAllCommercialFilter(filter: ComercialFilter): Observable<any> {
        const url = environment.quotit + `/commercial/findAllCommercial`;

        return this.http.post(url, filter).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }
7
    findAllSellerNavision(): Observable<any> {
        const url = environment.quotit + `/seller_qualiapi/`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

}
