<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
  <div mat-dialog-content class="air-theme">

    <div fxLayout="row" class="flexwrap">
      <div fxFlex="50" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario" *ngIf="false">
          <input matInput [(ngModel)]="data.workcenter.name" name="name" required
            placeholder="{{ 'quoteEdit.dialog.workcenter.name.label' | translate }}">
          <mat-error>
            {{ 'quoteEdit.dialog.area.name.error' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'quoteEdit.dialog.workcenter.name.label' | translate }}</mat-label>
          <mat-select name="name" [(ngModel)]="data.workcenter.name" 
          (selectionChange)="onCenterChange($event)">
            <mat-option *ngFor="let item of data.centers" [value]="item.nombre">
              {{ item.nombre }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ 'quoteEdit.general.idTimeZone' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="50" fxFlex.lt-md="100%" >
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.workcenter.address" name="address" required
            placeholder="{{ 'quoteEdit.dialog.workcenter.address.label' | translate }}">
        </mat-form-field>
      </div>
    </div>



  </div>
  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>