import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../utils/httpUtils';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class DepartementService {

    constructor(private http: HttpClient) { }

    findAllDepartementNavision(): Observable<any> {
        const url = environment.quotit + `/departement_qualiapi/`;

        return this.http.get(url).pipe(map(httpRes => HttpUtils.extractData(httpRes)));
    }

}
