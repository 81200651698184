<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
  <div mat-dialog-content class="air-theme">

    <div fxLayout="row" class="flexwrap">
      <div fxFlex="50" fxFlex.lt-md="100%" >
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'quoteEdit.dialog.quotewon.manager.label' | translate }}
          </mat-label>
          <mat-select #sellerSelect required name="owners"
              [(ngModel)]="cardOwner">
              <mat-select-filter [displayMember]="'Description'"
                  [array]="owners" (filteredReturn)="ownersFiltered =$event"
                  [noResultsMessage]="'common.noResults' | translate"
                  [placeholder]="'quoteEdit.dialog.quotewon.manager.label' | translate">
              </mat-select-filter>
              <mat-option *ngFor="let cards of ownersFiltered"
                  [value]="cards.id">
                  {{ cards.name }}
              </mat-option>
          </mat-select>
          <mat-error>
              {{ 'quoteEdit.general.commercial.error' | translate }}
          </mat-error>
      </mat-form-field>
      </div>
    </div>


  </div> 
  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>