<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
  <div mat-dialog-content class="air-theme">
    <div fxLayout="row" fxLayout.lt-md="column" class="flexwrap">
      
      <div fxFlex="33" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'quoteEdit.dialog.quotewon.ordernum.label' | translate }}</mat-label>
          <input matInput [(ngModel)]="data.orderNum" name="orderNum" type="text">
        </mat-form-field>
      </div>

      <div fxFlex="33" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'quoteEdit.dialog.quotewon.manager.label' | translate }}</mat-label>
          <mat-select required name="projectManager" [(ngModel)]="data.projectManager">
            <mat-select-filter [displayMember]="'Description'"
                [array]="sellers" (filteredReturn)="sellersFiltered = $event"
                [noResultsMessage]="'common.noResults' | translate"
                [placeholder]="'quoteEdit.dialog.quotewon.manager.label' | translate">
            </mat-select-filter>
            <mat-option *ngFor="let item of sellersFiltered" [value]="item.Code">
              {{ item.Description }}
            </mat-option>
          </mat-select>
          <mat-error>{{ 'quoteEdit.general.commercial.error' | translate }}</mat-error>
        </mat-form-field>
      </div>

      <div fxFlex="33" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'quoteEdit.dialog.quotewon.comercial.label' | translate }}</mat-label>
          <mat-select required name="commercialManager" [(ngModel)]="data.pipeDriveCommercial">
            <mat-select-filter [displayMember]="'Description'"
                [array]="pipeDriveCardOwners" (filteredReturn)="pipeDriveCardOwnersFiltered = $event"
                [noResultsMessage]="'common.noResults' | translate"
                [placeholder]="'quoteEdit.dialog.quotewon.comercial.label' | translate">
            </mat-select-filter>
            <mat-option *ngFor="let item of pipeDriveCardOwnersFiltered" [value]="item.id">
              {{ item.name }}
            </mat-option>
          </mat-select>
          <mat-error>{{ 'quoteEdit.general.commercial.error' | translate }}</mat-error>
        </mat-form-field>
      </div>

    </div>
  </div>

  <div mat-dialog-content class="air-theme">
    <div fxLayout="row" class="flexwrap">
      <div fxFlex="100" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <textarea rows="5" matInput [(ngModel)]="data.others" name="others"
            placeholder="{{ 'quoteEdit.dialog.quotewon.notes.label' | translate }}">
          </textarea>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">
      {{ 'button.save' | translate }}
    </button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">
      {{ 'button.cancel' | translate }}
    </button>
  </div>
</form>