<ng-template mat-tab-label>{{ 'quoteEdit.general.title' | translate }} </ng-template>

<mat-tab-group #quoteTabs (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedTabIndex"
    (selectedIndexChange)="selectedTabIndex">
    <mat-tab #quoteTab label="{{ 'quoteEdit.general.title' | translate }}">
        <form>
            <mat-card class="card">
                <mat-card-content>
                    <mat-toolbar color="secondary">
                        {{ 'quoteEdit.general.title' | translate }}
                    </mat-toolbar>

                    <div fxLayout="row" class="flexwrap">

                        <div fxFlex="100" fxFlex.lt-md="100%">
                            <div fxLayout="column">
                                <!-- 1 row-->
                                <div fxLayout="row" class="flexwrap">
                                     <!-- CLIENTE -->
                                    <div fxFlex="25" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'quoteEdit.general.client.label' | translate }}</mat-label>
                                            <mat-select #clientSelect required name="idClient"
                                                [(ngModel)]="quoteDTO.idClient"
                                                [disabled]="quoteDTO.idStatus > 1"
                                                (selectionChange)="onClientChange($event)">
                                                <mat-select-filter [displayMember]="'name'" [array]="clients"
                                                    (filteredReturn)="clientsFiltered =$event"
                                                    [noResultsMessage]="'common.noResults' | translate"
                                                    [placeholder]="'common.selectFilterPlaceholder' | translate">
                                                </mat-select-filter>
                                                <mat-option *ngFor="let itemClient of clientsFiltered"
                                                    [value]="itemClient.cliid">
                                                    {{ itemClient.name }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'quoteEdit.general.client.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- Direccion -->
                                    <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="client != null">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="address" [(ngModel)]="client.direccion" disabled
                                                placeholder="{{ 'quoteEdit.general.address.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                    <!-- Contacto -->
                                    <div fxFlex="15" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'quoteEdit.general.contactName.label' | translate }}
                                            </mat-label>
                                            <mat-select required name="contactName"
                                                [(ngModel)]="quoteDTO.idClientContact"
                                                (selectionChange)="ontContactChange($event)"
                                                [disabled]="quoteDTO.idStatus > 1">
                                                <mat-select-filter [displayMember]="'fullName'" [array]="contacts"
                                                    (filteredReturn)="contactsFiltered =$event"
                                                    [noResultsMessage]="'common.noResults' | translate"
                                                    [placeholder]="'common.selectFilterPlaceholder' | translate">
                                                </mat-select-filter>
                                                <mat-option *ngFor="let contact of contactsFiltered"
                                                    [value]="contact.id">
                                                    {{ contact.fullName }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'quoteEdit.general.contact.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- EMAIL DEL Contacto -->
                                    <div fxFlex="20" fxFlex.lt-md="100%" *ngIf="contact != null">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="email" [(ngModel)]="contact.email" disabled
                                                placeholder="{{ 'quoteEdit.general.email.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                    <!-- Zona horaria -->
                                    <div fxFlex="7" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                          <mat-label>{{ 'quoteEdit.general.timezone' | translate }}</mat-label>
                                          <mat-select name="idTimeZone" [(ngModel)]="quoteDTO.idTimeZone" (selectionChange)="onTimeZoneChange()"
                                          [disabled]="quoteDTO.idStatus > 1">
                                            <mat-option *ngFor="let item of timeZones" [value]="item.id">
                                              {{ item.zone }}
                                            </mat-option>
                                          </mat-select>
                                          <mat-error>
                                            {{ 'quoteEdit.general.idTimeZone' | translate }}
                                          </mat-error>
                                        </mat-form-field>
                                      </div>
                                    <!-- centro responsable-->
                                    <div fxFlex="8" fxFlex.lt-md="100%">
                                    <mat-form-field class="campoFormulario">
                                        <mat-label>{{ 'quoteList.filter.responsabilityCenter.label' | translate }}</mat-label>
                                        <mat-select name="idR" [(ngModel)]="quoteDTO.idCenterNav" [disabled]="quoteDTO.idStatus > 1">
                                        <mat-option *ngFor="let item of responsabilityCenters" [value]="item.Code">
                                            {{ item.Code }}
                                        </mat-option>
                                        </mat-select>                                          
                                    </mat-form-field>
                                    </div>
                                    {{quoteDTO.pipeDriveDeal}}
                                    <div fxFlex="10" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'quoteEdit.general.deal.label' | translate }}</mat-label>

                                            <mat-select #clientSelect required name="pipeDeal"
                                                [(ngModel)]="quoteDTO.pipeDriveDeal"
                                                [disabled]="quoteDTO.idStatus > 1" 
                                                (selectionChange)="onDealChange($event)">
                                                
                                                <mat-option *ngFor="let deal of pipeDriveDealsFiltered"
                                                    [value]="deal.id">
                                                    {{ deal.title }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'quoteEdit.general.client.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div fxFlex="100" fxFlex.lt-md="100%">
                            <div fxLayout="column">
                                <!-- 2 row-->
                                <div fxLayout="row" class="flexwrap">
                                    <!-- tipo oferta-->
                                    <div fxFlex="15" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'quoteEdit.general.quoteType' | translate }}</mat-label>
                                            <mat-select required name="offerTypeNav" [(ngModel)]="quoteDTO.offerTypeNav"
                                            [disabled]="quoteDTO.idStatus > 1">
                                                <mat-option *ngFor="let item of quoteTypesNav" [value]="item.Code">
                                                    {{ item.Description }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'quoteEdit.general.quoteType.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- Nº DE LA OFERTA -->
                                    <div fxFlex="15" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="quoteNumber" [(ngModel)]="quoteDTO.quoteNumber"
                                                disabled
                                                placeholder="{{ 'quoteEdit.general.quoteNumber.label' | translate }}">
                                                <mat-error >{{'quoteEdit.general.quoteNumber.error' | translate}}</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- Estado oferta -->
                                    <div fxFlex="10" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="status" [(ngModel)]="quoteDTO.translateStatus"
                                                disabled placeholder="{{ 'quoteEdit.general.status'| translate }}">
                                        </mat-form-field>
                                    </div>
                                    <!-- Version oferta -->
                                    <div fxFlex="10" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="nVersion" [(ngModel)]="quoteDTO.numVersion"
                                                disabled
                                                placeholder="{{ 'quoteEdit.general.numVersion'| translate }}">
                                        </mat-form-field>
                                    </div>
                                    
                                    <!-- tipo proyecto-->
                                    <div fxFlex="15" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'quoteEdit.general.projectType.label' | translate }}</mat-label>
                                            <mat-select required name="projectType" [(ngModel)]="quoteDTO.projectTypeNav" [disabled]="quoteDTO.idStatus > 1">
                                            <mat-select-filter [displayMember]="'Description'" [array]="projectTypeNav"
                                                (filteredReturn)="projectTypeNavFiltered =$event"
                                                [noResultsMessage]="'common.noResults' | translate"
                                                [placeholder]="'common.selectFilterPlaceholder' | translate">
                                            </mat-select-filter>
                                                <mat-option *ngFor="let item of projectTypeNavFiltered" [value]="item.Code">
                                                    {{ item.Description }}
                                                </mat-option>
                                               
                                            </mat-select>
                                            <mat-error>
                                                {{ 'quoteEdit.general.projectType.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- subtipo proyecto-->
                                    <div fxFlex="15" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'quoteEdit.general.subTypeProject.label' | translate }}</mat-label>
                                            <mat-select required name="quoteType" [(ngModel)]="quoteDTO.projectSubTypeNav" [disabled]="quoteDTO.idStatus > 1">
                                                <mat-select-filter [displayMember]="'Description'" [array]="projectSubTypeNav"
                                                    (filteredReturn)="projectSubTypeNavFiltered =$event"
                                                    [noResultsMessage]="'common.noResults' | translate"
                                                    [placeholder]="'common.selectFilterPlaceholder' | translate">
                                                </mat-select-filter>
                                                <mat-option *ngFor="let item of projectSubTypeNavFiltered" [value]="item.Code">
                                                    {{ item.Description }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'quoteEdit.general.subTypeProject.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- numero proyecto-->
                                    <div fxFlex="20" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="projectNumber" [(ngModel)]="quoteDTO.projectNumber"
                                                disabled placeholder="{{ 'quoteEdit.general.projectNumber'| translate }}">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="100" fxFlex.lt-md="100%">
                            <div fxLayout="column">

                                <div fxLayout="row" class="flexwrap">
                                    <!-- DESCRIPCION DEL SERVICIO -->
                                    <div fxFlex="100" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="descriptionService" required
                                                [(ngModel)]="quoteDTO.descriptionService"
                                                [disabled]="quoteDTO.idStatus > 1"
                                                placeholder="{{ 'quoteEdit.general.descriptionService.label' | translate }}">
                                                <mat-error >{{ 'quoteEdit.general.descriptionService.error' | translate }}</mat-error>
                                            </mat-form-field>
                                    </div>
                                </div>

                                <div fxLayout="row" class="flexwrap">

                                    <div fxFlex="100" fxFlex.lt-md="100%">
                                        <!-- ALCANCE -->
                                        <mat-form-field class="campoFormulario">
                                            <input matInput name="scope" [(ngModel)]="quoteDTO.scope"
                                            [disabled]="quoteDTO.idStatus > 1"
                                                placeholder="{{ 'quoteEdit.general.scope.label' | translate }}">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="100" fxFlex.lt-md="100%">
                            <div fxLayout="column">

                                <div fxLayout="row" class="flexwrap">
                                    <!-- METODO DE PAGO -->
                                    <div fxFlex="30" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'quoteEdit.general.paymentMethod.label' | translate }}
                                            </mat-label>
                                            <mat-select #contactSelect required name="idPaymentMethod"
                                                [(ngModel)]="quoteDTO.codePaymentMethod"
                                                [disabled]="quoteDTO.idStatus > 1">
                                            <mat-select-filter [displayMember]="'Description'" [array]="payments"
                                                (filteredReturn)="paymentFiltered =$event"
                                                [noResultsMessage]="'common.noResults' | translate"
                                                [placeholder]="'common.selectFilterPlaceholder' | translate">
                                            </mat-select-filter>
                                                <mat-option *ngFor="let payment of paymentFiltered" [value]="payment.Code">
                                                    {{ payment.Description | translate}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'quoteEdit.general.payment.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="30" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'quoteEdit.general.paymentTerm.label' | translate }}
                                            </mat-label>
                                            <mat-select #contactSelect required name="idPaymentTerm"
                                                [(ngModel)]="quoteDTO.codePaymentTerm"
                                                [disabled]="quoteDTO.idStatus > 1">
                                                <mat-select-filter [displayMember]="'Description'" [array]="terms"
                                                    (filteredReturn)="termsFiltered =$event"
                                                    [noResultsMessage]="'common.noResults' | translate"
                                                    [placeholder]="'common.selectFilterPlaceholder' | translate">
                                                </mat-select-filter>
                                                <mat-option *ngFor="let term of termsFiltered" [value]="term.Code">
                                                    {{ term.Description | translate}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'quoteEdit.general.payment.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- Payment % -->
                                    <div fxFlex="15" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">

                                            <mat-select name="paymentPercentage" 
                                            [disabled]="quoteDTO.idStatus > 1"
                                                [(ngModel)]="quoteDTO.paymentPercentage"
                                                placeholder="{{ 'quoteEdit.general.paymentPercentage.label' | translate }}"
                                                matTooltip="{{ 'paymentPercentage.tooltip' | translate }}">
                                                <mat-option>--</mat-option>
                                                <mat-option *ngFor="let item of paymentModes" [value]="item.reference">
                                                    {{ item.reference }}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </div>
                                    <!-- DESCUENTO INCREMENTO -->
                                    <div fxFlex="25" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario" *ngIf="showIncremet()">

                                            <mat-select name="idDiscountIncrease"
                                                [disabled]="quoteDTO.idStatus > 1"
                                                [(ngModel)]="quoteDTO.valueDiscountIncrease"
                                                placeholder="{{ 'quoteEdit.general.discountIncrease.label' | translate }}"
                                                (selectionChange)="onDiscountIncreaseChange($event)">
                                                <mat-option>--</mat-option>
                                                <mat-option *ngFor="let item of valuesDiscountIncrease" [value]="item">
                                                    {{ 'quoteEdit.general.'+item | translate}}
                                                </mat-option>
                                            </mat-select>

                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="25" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario" *ngIf="quoteDTO.valueDiscountIncrease">
                                            <input matInput required type="number"
                                                [disabled]="quoteDTO.idStatus > 1"
                                                [(ngModel)]="quoteDTO.discountIncrease" name="discountIncrease"
                                                placeholder="{{ 'quoteEdit.general.percentage.label' | translate }}">
                                            <mat-error>
                                                {{ 'quoteEdit.general.discountIncrease.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxFlex="100" fxFlex.lt-md="100%">
                            <div fxLayout="column">
                                <div fxLayout="row" class="flexwrap">
                                    <!-- COMERCIAL -->
                                    <div fxFlex="30" fxFlex.lt-md="100%">
                                        <mat-form-field class="campoFormulario">
                                            <mat-label>{{ 'quoteEdit.general.commercial.label' | translate }}
                                            </mat-label>
                                            <mat-select #sellerSelect required name="seller"
                                                [(ngModel)]="quoteDTO.commercialCodeNav"
                                                [disabled]="quoteDTO.idStatus > 1">
                                                <mat-select-filter [displayMember]="'Description'"
                                                    [array]="sellers" (filteredReturn)="sellersFiltered =$event"
                                                    [noResultsMessage]="'common.noResults' | translate"
                                                    [placeholder]="'quoteEdit.general.commercial.label' | translate">
                                                </mat-select-filter>
                                                <mat-option *ngFor="let itemCommercial of sellersFiltered"
                                                    [value]="itemCommercial.Code">
                                                    {{ itemCommercial.Description }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{ 'quoteEdit.general.commercial.error' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <!-- CHECK BOXS -->
                                    <div fxFlex="70" fxFlex.lt-md="100%">
                                        <!-- CHECK BOX TRABAJO EXCEPCIONAL -->
                                        <div fxFlex="35" fxFlex.lt-md="100%" div fxLayout="row" *ngIf="quoteDTO"
                                            fxLayoutAlign="start center">
                                            <mat-checkbox
                                                [disabled]="quoteDTO.idStatus > 1"
                                                matTooltip="{{ 'quoteEdit.general.button.exclusiveUrgentService' | translate }}"
                                                [(ngModel)]="quoteDTO.exclusiveUrgentService"
                                                name="exclusiveUrgentService">
                                                {{ 'quoteEdit.general.button.exclusiveUrgentService' | translate }}
                                            </mat-checkbox>
                                        </div>
                                        <!-- CHECK BOX CON PROTOCOLOS -->
                                        <div fxFlex="25" fxFlex.lt-md="100%" div fxLayout="row"
                                            fxLayoutAlign="start center">
                                            <mat-checkbox
                                                [disabled]="quoteDTO.idStatus > 1"
                                                matTooltip="{{ 'quoteEdit.general.button.withProtocol' | translate }}"
                                                [(ngModel)]="quoteDTO.withProtocol" name="quoteWithProt">
                                                {{ 'quoteEdit.general.button.withProtocol' | translate }}
                                            </mat-checkbox>
                                        </div>
                                        <!-- CHECK BOX CON RECURRENTE -->
                                        <div fxFlex="20" fxFlex.lt-md="100%" div fxLayout="row"
                                            fxLayoutAlign="start center">
                                            <mat-checkbox
                                                matTooltip="{{ 'quoteEdit.general.button.recalculate' | translate }}"
                                                [(ngModel)]="quoteDTO.recalculate" name="quoteRecalculate"
                                                [disabled]="quoteDTO.idStatus > 1">
                                                {{ 'quoteEdit.general.button.recalculate' | translate }}
                                            </mat-checkbox>
                                        </div>
                                        <!-- CHECK BOX IMPLANT -->
                                        <div fxFlex="20" fxFlex.lt-md="100%" div fxLayout="row"
                                            fxLayoutAlign="start center">
                                            <mat-checkbox
                                                matTooltip="{{ 'quoteEdit.general.button.implant' | translate }}"
                                                [(ngModel)]="quoteDTO.implant" name="quoteImplant"
                                                [disabled]="quoteDTO.idStatus > 1">
                                                {{ 'quoteEdit.general.button.implant' | translate }}
                                            </mat-checkbox>
                                        </div>
                                         <!-- CHECK BOX NO SCOPE -->
                                         <div fxFlex="20" fxFlex.lt-md="100%" div fxLayout="row"
                                         fxLayoutAlign="start center">
                                         <mat-checkbox
                                             matTooltip="{{ 'quoteEdit.general.button.noScope' | translate }}"
                                             [(ngModel)]="quoteDTO.noScope" name="quoteNoScope"
                                             [disabled]="quoteDTO.idStatus > 1">
                                             {{ 'quoteEdit.general.button.noScope' | translate }}
                                         </mat-checkbox>
                                        </div>

                                        <!-- CHECK BOX JOURNAL -->
                                        <div fxFlex="20" fxFlex.lt-md="100%" div fxLayout="row"
                                        fxLayoutAlign="start center">
                                        <mat-checkbox
                                            matTooltip="{{ 'quoteEdit.general.button.addJournal' | translate }}"
                                            [(ngModel)]="quoteDTO.journal" name="quoteJournal"
                                            [disabled]="quoteDTO.idStatus > 1"  >
                                            {{ 'quoteEdit.general.button.addJournal' | translate }}
                                        </mat-checkbox>
                                       </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="quoteDTO.journal">
                            <div fxLayout="column">
                                <div fxLayout="row" class="flexwrap">
                                    <mat-form-field class="campoFormulario">
                                        <input  matInput type="number" [(ngModel)]="quoteDTO.numberOfJournals"  required min="0" name="numberOfJournals"
                                          placeholder="{{ 'quoteEdit.general.button.addJournalNumber' | translate }}">
                                          <mat-error *ngIf = "quoteDTO.numberOfJournals < 0 || quoteDTO.numberOfJournals == null">
                                            {{ 'quoteEdit.general.button.addJournalNumber.error' | translate }}
                                          </mat-error>
                                      </mat-form-field>
                                </div>
                            </div>    
                        </div>

                    </div>

                </mat-card-content>
            </mat-card>
            <mat-expansion-panel class="overflowX" *ngIf="!quoteDTO.noScope && quoteDTO.id > 0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-toolbar color="secondary">
                            {{ 'quoteEdit.workcenter.title' | translate }}

                            <button mat-icon-button color="accent" (click)="openDialogWorkcenter()"
                            [disabled]="quoteDTO.idStatus > 1">
                                <mat-icon>add</mat-icon>
                            </button>
                        </mat-toolbar>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- WorkCenters -->
                <mat-card class="card">
                    <mat-card-content>

                        <mat-tab-group #workcenterTabs (selectedTabChange)="onWorkcenterTabChanged($event)">
                            <mat-tab *ngFor="let workcenter of quoteDTO.workcenters; let indexWC = index">

                                <ng-template mat-tab-label>
                                    {{ workcenter.name }}
                                    <button mat-icon-button color="accent" *ngIf="workcenterTab === indexWC"
                                        (click)="openDialogEditWorkcenter(indexWC)"
                                        [disabled]="quoteDTO.idStatus > 1">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>

                                    <button mat-icon-button color="accent" *ngIf="workcenterTab === indexWC"
                                        (click)="deleteWorkcenter(indexWC)"
                                        [disabled]="quoteDTO.idStatus > 1">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </ng-template>
                                <br />
                                <!-- Diets -->
                                <mat-toolbar color="secondary">
                                    {{ 'quoteEdit.diet.title' | translate }}

                                    <button mat-icon-button color="accent" (click)="openDialogDiet(workcenter)"
                                    [disabled]="quoteDTO.idStatus > 1">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </mat-toolbar>
                                <mat-list>
                                    <mat-list-item *ngFor="let diet of workcenter.dietDTOs; let indexDiet = index">

                                        <div fxLayout="row">
                                                <div fxLayout="row">
                                                    <span><strong>{{ 'quoteEdit.general.dietTravel.label' | translate
                                                            }}: </strong>
                                                        {{ showDietName(diet.dietCodeNav) | translate }} </span>
                                                </div>

                                                <div fxLayout="row">
                                                    <span><strong>{{ 'quoteEdit.dialog.dietTravel.amount.label' |
                                                            translate }}: </strong>
                                                        {{ diet.amount }} </span>
                                                </div>
                                                <div fxLayout="row">
                                                    <span><strong>{{ 'quoteEdit.dialog.departement.label' |
                                                            translate }}: </strong>
                                                        {{ diet.departmentCodeNav }} </span>
                                                </div>
                                                <div fxLayout="row">
                                                    <span><strong>{{ 'quoteEdit.dialog.resource.label' |
                                                            translate }}: </strong>
                                                        {{ diet.resourceCodeNav }}</span>
                                                </div>

                                            <button mat-icon-button color="accent"
                                                (click)="openDialogEditDiet(indexWC,indexDiet)">
                                                <mat-icon>mode_edit</mat-icon>
                                            </button>

                                            <button mat-icon-button color="accent"
                                                (click)="deleteWorkDiet(indexWC,indexDiet)"
                                                [disabled]="quoteDTO.idStatus > 1">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </mat-list-item>
                                    <br>
                                </mat-list>
                                <!-- Thermal -->
                                <div color="secondary" fxLayout="column">
                                    <div class="panel-div-title">
                                         Producción 
                                    </div>

                                    <!-- Equipment Thermal -->
                                    <mat-toolbar color="secondary">
                                        {{ 'quoteEdit.EquipmentThermal.title' | translate }}
                                        <button mat-icon-button color="accent"
                                            [disabled]="quoteDTO.idStatus > 1"
                                            (click)="openDialogEquipmentThermal(indexWC, false)">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </mat-toolbar>
                                    <mat-list >
                                        <mat-list-item
                                            *ngFor="let equipmentThermal of workcenter.equipmentThermal; let indexThermal = index">
                                            <div fxLayout="row">

                                                <div fxLayout="column">
                                                    <div fxLayout="row">
                                                        <span>
                                                            <strong>
                                                                {{'equipmentType.equipment' |translate}}:
                                                                {{equipmentThermal.equipment}}
                                                                {{'(S/N:'}}
                                                            </strong>
                                                            {{ equipmentThermal.serialNumber || "NA" }}{{')'}}</span>
                                                        <span><strong>{{'(Id.'}} </strong>
                                                            {{ equipmentThermal.identificator || "NA" }}{{')'}}</span>
                                                            {{' Intervención:'}}{{equipmentThermal.intervention}} 
                                                    </div>

                                                    <div fxLayout="row">
                                                        <span><strong> {{ 'quoteEdit.equipment.thermal.location' |
                                                                translate }} </strong>
                                                            {{ equipmentThermal.location }}</span>
                                                    </div>
                                                </div>

                                                <button mat-icon-button color="accent"
                                                    matTooltip="{{ 'button.duplicate' | translate }}"
                                                    [disabled]="quoteDTO.idStatus > 1"
                                                    (click)="cloneEquipmentThermal(indexWC,indexThermal)">
                                                    <mat-icon>control_point_duplicate</mat-icon>
                                                </button>
                                                <button mat-icon-button color="accent"
                                                [disabled]="quoteDTO.idStatus > 1"
                                                    (click)="editEquipmentThermal(indexWC,indexThermal, false)">
                                                    <mat-icon>mode_edit</mat-icon>
                                                </button>
                                                <button mat-icon-button color="accent"
                                                [disabled]="quoteDTO.idStatus > 1"
                                                    (click)="deleteEquipmentThermal(indexWC,indexThermal)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </mat-list-item>
                                    </mat-list>
                                    
                                </div>
                                <!-- Aire -->
                                <div color="secondary" fxLayout="column">
                                    <mat-toolbar color="secondary" class="tab-left">
                                        {{ getTranslationFromQuoteType(quoteDTO.idType) | translate }}
                                        <button mat-icon-button color="accent" (click)="openDialogArea(indexWC)"
                                        [disabled]="quoteDTO.idStatus > 1">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </mat-toolbar>

                                    <mat-tab-group #areaTabs (selectedTabChange)="onAreaTabChanged($event)">
                                        <!--AREAS -->
                                        <mat-tab *ngFor="let area of workcenter.areas; let indexArea = index">

                                            <ng-template mat-tab-label>
                                                {{ area.name }}

                                                <button mat-icon-button color="accent" *ngIf="indexArea>-1"
                                                    (click)="openDialogEditArea(indexWC, indexArea)"
                                                    [disabled]="quoteDTO.idStatus > 1">
                                                    <mat-icon>mode_edit</mat-icon>
                                                </button>

                                                <button mat-icon-button color="accent" *ngIf="indexArea > -1"
                                                    (click)="deleteArea(indexWC, indexArea)"
                                                    [disabled]="quoteDTO.idStatus > 1">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </ng-template>

                                            <!-- BOTON AÑADIR EQUIPOS DE AIRE -->
                                            <mat-toolbar color="secondary">
                                                {{ 'quoteEdit.general.equipmentAir.title' | translate }}

                                                <button mat-icon-button color="accent"
                                                    (click)="openDialogEquipment(indexWC, indexArea)"
                                                    [disabled]="quoteDTO.idStatus > 1">
                                                    <mat-icon>add</mat-icon>
                                                </button>

                                                <button mat-icon-button color="accent"
                                                    (click)="openDialogUploadMasiveEquipment(indexWC, indexArea)">
                                                    <mat-icon>upload</mat-icon>
                                                </button>
                                            </mat-toolbar>
                                            <!-- EQUIPOS AIRE -->
                                            <mat-accordion>
                                                <mat-expansion-panel class="overflowX"
                                                    *ngFor="let equipment of area.equipments; let indexEquipment = index"
                                                    (afterExpand)="equipmentTab = indexEquipment"
                                                    (closed)="equipmentTab = null">
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                        
                                                            <div fxLayout="column">
                                                                <div fxLayout="row">
                                                                    <span>
                                                                        <strong>{{'equipmentType.equipment' |translate}}</strong>:
                                                                        {{getEquipmentType(equipment) | translate}}
                                                                        <strong>{{'(S/N: '}}</strong>
                                                                        {{ equipment.serialNumber || "NA" }}{{') '}}</span>
                                                                    <span>
                                                                        <strong>{{'(Model: '}} </strong>
                                                                        {{ equipment.model || "NA" }}{{')'}}
                                                                    </span>
                                                                </div>
            
                                                                <div fxLayout="row">
                                                                    <span>
                                                                        <strong> {{ 'quoteEdit.equipment.thermal.location' | translate }} </strong>
                                                                        {{ equipment.location }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <!-- EDITAR EQUIPOS AIRE -->
                                                            <button mat-icon-button color="accent"
                                                                *ngIf="equipmentTab === indexEquipment"
                                                                (click)="openDialogEditEquipment(indexWC,indexArea,indexEquipment, false)"
                                                                [disabled]="(quoteDTO.idStatus| quoteStatus: 'signed')">
                                                                <mat-icon style="font-size: 20px;">mode_edit</mat-icon>
                                                            </button>

                                                            <!-- ELIMINAR EQUIPOS AIRE -->
                                                            <button mat-icon-button color="accent"
                                                                *ngIf="equipmentTab === indexEquipment"
                                                                (click)="deleteEquipment(area,indexEquipment)"
                                                                [disabled]="quoteDTO.idStatus > 1">
                                                                <mat-icon style="font-size: 20px;">delete</mat-icon>
                                                            </button>
                                                            <button mat-icon-button color="accent"
                                                                *ngIf="equipmentTab === indexEquipment && false"
                                                                (click)="duplicateEquipment(indexArea, indexEquipment)">
                                                            </button>
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                        <strong>{{ "quoteEdit.dialog.equipment.test" |
                                                            translate }}: </strong>
                                                        <div fxFlex="100" fxFlex.lt-md="100%">
                                                            {{ getTestTypeString(equipment.tests) }}
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                        <div fxFlex="15" fxFlex.lt-md="100%"
                                                            *ngFor="let qualification of equipment.qualifications">
                                                            {{ getQualificationName(qualification.idType) |
                                                            translate }}
                                                        </div>
                                                    </div>

                                                </mat-expansion-panel>
                                            </mat-accordion>
                                            <br />
                                            <!-- BOTON AÑADIR SALAS DE AIRE -->
                                            <mat-toolbar color="secondary">
                                                {{ 'quoteEdit.general.roomAir.title' | translate }}

                                                <button mat-icon-button color="accent"
                                                    (click)="openDialogRoom(indexWC, indexArea, false)"
                                                    [disabled]="quoteDTO.idStatus > 1">
                                                    <mat-icon>add</mat-icon>
                                                </button>

                                                <button mat-icon-button color="accent"
                                                    (click)="openDialogUploadMasiveRoom(indexWC, indexArea)">
                                                    <mat-icon>upload</mat-icon>
                                                </button>
                                            </mat-toolbar>
                                            <!-- SALAS AIRE -->
                                            <mat-accordion>
                                                <mat-expansion-panel class="overflowX"
                                                    *ngFor="let room of area.rooms; let roomIndex = index"
                                                    (afterExpand)="roomTab = roomIndex" (closed)="roomTab = null">
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            <div fxLayout="row">
                                                                <span>
                                                                    <strong>{{'quoteEdit.equipment.room.title' |translate}}</strong>:
                                                                    {{room.name}}
                                                                    <strong>{{'(N° Sala: '}}</strong>
                                                                    {{ room.roomNumber || "NA" }}{{') '}}
                                                                </span>
                                                            </div>
                                                            <!-- EDITAR SALAS -->
                                                            <button mat-icon-button color="accent"
                                                                *ngIf="roomTab === roomIndex"
                                                                (click)="openDialogEditRoom(indexWC,indexArea,roomIndex, false)"
                                                                [disabled]="quoteDTO.idStatus > 1">
                                                                <mat-icon style="font-size: 20px;">mode_edit</mat-icon>
                                                            </button>

                                                            <!-- ELIMINAR SALAS -->
                                                            <button mat-icon-button color="accent"
                                                                *ngIf="roomTab === roomIndex"
                                                                (click)="deleteRoom(area,roomIndex)"
                                                                [disabled]="quoteDTO.idStatus > 1">
                                                                <mat-icon style="font-size: 20px;">delete</mat-icon>
                                                            </button>
                                                            <button mat-icon-button color="accent"
                                                                *ngIf="roomTab === roomIndex && false"
                                                                (click)="duplicateEquipment(indexWC,indexArea,roomIndex)">
                                                            </button>
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                        <strong>{{ "quoteEdit.dialog.equipment.test" | translate }}: </strong>
                                                        <div fxFlex="100" fxFlex.lt-md="100%">
                                                            {{ getTestTypeString(room.tests) }}
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                        <div fxFlex="15" fxFlex.lt-md="100%" *ngFor="let qualification of room.qualifications">
                                                            {{ getQualificationName(qualification.idType) | translate }}
                                                        </div>
                                                    </div>
                                                </mat-expansion-panel>
                                            </mat-accordion>
                                            <br />
                                            <!-- BOTON AÑADIR GASES DE AIRE -->
                                            <mat-toolbar color="secondary">
                                                {{ 'quoteEdit.general.compressedGas.title' | translate }}

                                                <button mat-icon-button color="accent"
                                                    (click)="openDialogGas(indexWC, indexArea, false)"
                                                    [disabled]="quoteDTO.idStatus > 1">
                                                    <mat-icon>add</mat-icon>
                                                </button>

                                                <button mat-icon-button color="accent"
                                                    (click)="openDialogUploadMasiveGas(indexWC, indexArea)"
                                                    >
                                                    <mat-icon>upload</mat-icon>
                                                </button>
                                            </mat-toolbar>
                                            <!-- GASES AIRE -->
                                            <mat-accordion>
                                                <mat-expansion-panel class="overflowX"
                                                    *ngFor="let gas of area.compressedGases; let gasIndex = index"
                                                    (afterExpand)="gasTab = gasIndex" (closed)="gasTab = null">
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-title>
                                                            <div fxLayout="column">
                                                                <div fxLayout="row">
                                                                    <span>
                                                                        <strong>{{'quoteEdit.equipment.gas.title' |translate}}</strong>:
                                                                        {{showGasName(gas)}}
                                                                        <strong>{{'(N° Gas: '}}</strong>
                                                                        {{ gas.gasPoints || "NA" }}{{') '}}
                                                                    </span>
                                                                </div>
            
                                                                <div fxLayout="row">
                                                                    <span>
                                                                        <strong> {{ 'quoteEdit.equipment.gas.location' | translate }} </strong>
                                                                        {{ gas.location }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <!-- EDITAR GASES -->
                                                            <button mat-icon-button color="accent"
                                                                *ngIf="gasTab === gasIndex"
                                                                (click)="openDialogEditGas(indexWC,indexArea, gasIndex, false)"
                                                                [disabled]="quoteDTO.idStatus > 1">
                                                                <mat-icon style="font-size: 20px;">mode_edit</mat-icon>
                                                            </button>
                                                            <!-- ELIMINAR GASES -->
                                                            <button mat-icon-button color="accent"
                                                                *ngIf="gasTab === gasIndex"
                                                                (click)="deleteGas(area, gasIndex)"
                                                                [disabled]="quoteDTO.idStatus > 1">
                                                                <mat-icon style="font-size: 20px;">delete</mat-icon>
                                                            </button>
                                                            <button mat-icon-button color="accent"
                                                                *ngIf="asTab === gasIndex && false"
                                                                [disabled]="quoteDTO.idStatus > 1"
                                                                (click)="duplicateEquipment(indexWC,indexArea, gasIndex)">
                                                            </button>
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>
                                                    <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                        <strong>{{ "quoteEdit.dialog.equipment.test" |
                                                            translate }}: </strong>
                                                        <div fxFlex="100" fxFlex.lt-md="100%">
                                                            {{ getTestTypeString(gas.tests) }}
                                                        </div>
                                                    </div>
                                                    <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                        <div fxFlex="15" fxFlex.lt-md="100%"
                                                            *ngFor="let qualification of gas.qualifications">
                                                            {{ getQualificationName(qualification.idType) |
                                                            translate }}
                                                        </div>
                                                    </div>
                                                </mat-expansion-panel>
                                            </mat-accordion>
                                        </mat-tab>
                                    </mat-tab-group>
                                </div>
                                <mat-list ></mat-list>
                                <div color="secondary" fxLayout="column">

                                    <!-- Calibrations -->
                                    <mat-toolbar color="secondary">
                                        {{ 'quote.calibrations.title' | translate }}
                                        <button mat-icon-button color="accent" (click)="openDialogCalibration(indexWC)"
                                        [disabled]="quoteDTO.idStatus > 1">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                        <button mat-icon-button color="accent"
                                            (click)="openDialogUploadMasiveCalibration(indexWC)"
                                            [disabled]="quoteDTO.idStatus > 1">
                                            <mat-icon>upload</mat-icon>
                                        </button>
                                        <button mat-icon-button color="accent"
                                            (click)="downloadCalibrationExcel(workcenter.id)"
                                            >
                                            <mat-icon>download</mat-icon>
                                        </button>
                                    </mat-toolbar>
                                    <mat-list>
                                        <mat-list-item
                                            *ngFor="let calibration of workcenter.calibrations; let indexCalibration = index">
                                            <div>

                                                {{ showCalibrationName(calibration) | translate }}
                                                <button mat-icon-button color="accent"
                                                    matTooltip="{{ 'button.duplicate' | translate }}"
                                                    [disabled]="quoteDTO.idStatus > 1"
                                                    (click)="cloneCalibration(indexWC,indexCalibration)">
                                                    <mat-icon>control_point_duplicate</mat-icon>
                                                </button>
                                                <button mat-icon-button color="accent"
                                                    matTooltip="{{ 'button.edit' | translate }}"
                                                    [disabled]="quoteDTO.idStatus > 1"
                                                    (click)="openDialogEditCalibration(indexWC,indexCalibration)">
                                                    <mat-icon>mode_edit</mat-icon>
                                                </button>
                                                <button mat-icon-button color="accent"
                                                    matTooltip="{{ 'button.delete' | translate }}"
                                                    [disabled]="quoteDTO.idStatus > 1"
                                                    (click)="deleteCalibration(indexWC,indexCalibration)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </mat-list-item>
                                    </mat-list>
                                </div>
                                <!-- Consultancy -->
                                <div color="secondary" fxLayout="column">
                                    <div class="panel-div-title">
                                        {{'quoteEdit.general.consultancy.title' | translate }}
                                    </div>
                                    <!-- BOTON AÑADIR TRAINING -->
                                    <mat-toolbar color="secondary" class="tab-left">
                                        {{ 'quoteEdit.general.training.title' | translate }}

                                        <button mat-icon-button color="accent" (click)=" openDialogTraining(indexWC)"
                                        [disabled]="quoteDTO.idStatus > 1">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </mat-toolbar>

                                    <mat-list>
                                        <mat-list-item
                                            *ngFor="let course of workcenter.courses; let indexCourse = index">
                                            <div>
                                                <div>
                                                    <span><strong>{{ showCourseName(course.idType) | translate }}:
                                                        </strong>
                                                        {{ course.price | currency:'EUR'}}</span>

                                                    <button mat-icon-button color="accent"
                                                        [disabled]="quoteDTO.idStatus > 1"
                                                        (click)="openDialogEditTraining (indexWC,indexCourse)">
                                                        <mat-icon>mode_edit</mat-icon>
                                                    </button>

                                                    <button mat-icon-button color="accent"
                                                        [disabled]="quoteDTO.idStatus > 1"
                                                        (click)="deleteTraining(indexWC,indexCourse)">

                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </mat-list-item>
                                    </mat-list>
                                    <!-- Audit -->
                                    <mat-toolbar color="secondary" class="tab-left">
                                        {{ 'quoteEdit.general.audit.title' | translate }}

                                        <button mat-icon-button color="accent" (click)="openDialogAudit(workcenter)"
                                        [disabled]="quoteDTO.idStatus > 1">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </mat-toolbar>
                                    <mat-list >
                                        <mat-list-item
                                            *ngFor="let audit of workcenter.auditDTOs; let indexAudit = index">
                                            <div>
                                                <div>
                                                    <span><strong>{{ showAuditName(audit.auditType) | translate }}
                                                        </strong> </span>

                                                    <button mat-icon-button color="accent"
                                                        (click)="openDialogEditAudit(indexWC,indexAudit)"
                                                        [disabled]="quoteDTO.idStatus > 1">
                                                        <mat-icon>mode_edit</mat-icon>
                                                    </button>

                                                    <button mat-icon-button color="accent"
                                                        (click)="deleteWorkAudit(indexWC,indexAudit)"
                                                        [disabled]="quoteDTO.idStatus > 1">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </mat-list-item>
                                    </mat-list>

                                    <!-- Aire -->
                                    <div color="secondary">
                                        <div class="panel-div-title subt-left" fxLayout="row">
                                            <div>
                                                {{'quoteEdit.general.air.title' | translate }}
                                            </div>

                                            <button mat-icon-button color="accent" (click)="openAirConsultancy()">
                                                <mat-icon>expand_more</mat-icon>
                                            </button>
                                        </div>

                                        <!-- Aire Cualificaciones -->
                                        <mat-tab-group #areaTabs (selectedTabChange)="onAreaTabChanged($event)">
                                            <!--AREAS -->
                                            <mat-tab *ngFor="let area of workcenter.areas; let indexArea = index">

                                                <ng-template mat-tab-label>
                                                    {{ 'quoteEdit.area.title'| translate}}
                                                </ng-template>

                                                <!-- BOTON AÑADIR EQUIPOS DE AIRE -->
                                                <mat-toolbar color="secondary" class="tab-left" area.equipments?.length > 0">
                                                    {{ 'quoteEdit.general.equipmentAir.title' | translate }}

                                                </mat-toolbar>
                                                <!-- EQUIPOS AIRE -->
                                                <mat-accordion>
                                                    <mat-expansion-panel class="overflowX"
                                                        *ngFor="let equipment of area.equipments; let indexEquipment = index"
                                                        (afterExpand)="equipmentTab = indexEquipment"
                                                        (closed)="equipmentTab = null">
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>
                                                                <div style="margin: auto 0;display: block;">
                                                                    <div style="margin: auto 0; display: block;">
                                                                        <strong>{{ 'equipmentType.equipment' | translate
                                                                            }}</strong>:
                                                                        {{ getEquipmentType(equipment) | translate }}
                                                                    </div>

                                                                </div>
                                                                <!-- EDITAR EQUIPOS AIRE -->
                                                                <button mat-icon-button color="accent"
                                                                    *ngIf="equipmentTab === indexEquipment"
                                                                    (click)="openDialogEditEquipment(indexWC,indexArea,indexEquipment, true)"
                                                                    [disabled]="quoteDTO.idStatus > 1">
                                                                    <mat-icon
                                                                        style="font-size: 20px;">mode_edit</mat-icon>
                                                                </button>
                                                            </mat-panel-title>
                                                        </mat-expansion-panel-header>
                                                        <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                            <strong>{{ "quoteEdit.dialog.equipment.test" |
                                                                translate }}: </strong>
                                                            <div fxFlex="10" fxFlex.lt-md="100%"
                                                                *ngFor="let test of equipment.tests">
                                                                {{ getTestName(test.idType) | translate }}
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                            <div fxFlex="15" fxFlex.lt-md="100%"
                                                                *ngFor="let qualification of equipment.qualifications; let first = first">
                                                                <div *ngIf="first">
                                                                    <span><strong>{{
                                                                            "quoteEdit.dialog.equipment.cualifications"
                                                                            | translate }}:</strong></span>
                                                                </div>
                                                                <div> {{ getQualificationName(qualification.idType) |
                                                                    translate }}</div>
                                                            </div>
                                                        </div>
                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <br />
                                                <!-- BOTON AÑADIR SALAS DE AIRE -->
                                                <mat-toolbar color="secondary" class="tab-left"
                                                    *ngIf="area.rooms?.length > 0">
                                                    {{ 'quoteEdit.general.roomAir.title' | translate }}
                                                </mat-toolbar>
                                                <!-- SALAS AIRE -->
                                                <mat-accordion>
                                                    <mat-expansion-panel class="overflowX"
                                                        *ngFor="let room of area.rooms; let roomIndex = index"
                                                        (afterExpand)="roomTab = roomIndex" (closed)="roomTab = null">
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>
                                                                <div style="margin: auto 0;display: block;">
                                                                    <strong> {{room.name}} </strong>
                                                                </div>
                                                                <!-- EDITAR SALAS -->
                                                                <button mat-icon-button color="accent"
                                                                    *ngIf="roomTab === roomIndex"
                                                                    (click)="openDialogEditRoom(indexWC,indexArea,roomIndex, true)"
                                                                    [disabled]="quoteDTO.idStatus > 1">
                                                                    <mat-icon
                                                                        style="font-size: 20px;">mode_edit</mat-icon>
                                                                </button>

                                                                <button mat-icon-button color="accent"
                                                                    *ngIf="roomTab === roomIndex && false"
                                                                    [disabled]="quoteDTO.idStatus > 1"
                                                                    (click)="duplicateEquipment(indexWC,indexArea,roomIndex)">
                                                                </button>
                                                            </mat-panel-title>
                                                        </mat-expansion-panel-header>
                                                        <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                            <strong>{{ "quoteEdit.dialog.equipment.test" |
                                                                translate }}: </strong>
                                                            <div fxFlex="10" fxFlex.lt-md="100%"
                                                                *ngFor="let test of room.tests">
                                                                {{ getTestName(test.idType) | translate }}
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                            <div fxFlex="15" fxFlex.lt-md="100%"
                                                                *ngFor="let qualification of room.qualifications; let first = first">
                                                                <div *ngIf="first">
                                                                    <span><strong>{{
                                                                            "quoteEdit.dialog.equipment.cualifications"
                                                                            | translate }}:</strong></span>
                                                                </div>
                                                                <div>
                                                                    {{ getQualificationName(qualification.idType) |
                                                                    translate }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <br />
                                                <!-- BOTON AÑADIR GASES DE AIRE -->
                                                <mat-toolbar color="secondary" class="tab-left"
                                                    *ngIf=" area.compressedGases?.length > 0">
                                                    {{ 'quoteEdit.general.compressedGas.title' | translate }}
                                                </mat-toolbar>
                                                <!-- GASES AIRE -->
                                                <mat-accordion>
                                                    <mat-expansion-panel class="overflowX"
                                                        *ngFor="let gas of area.compressedGases; let gasIndex = index"
                                                        (afterExpand)="gasTab = gasIndex" (closed)="gasTab = null">
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>
                                                                <div style="margin: auto 0;display: block;">
                                                                    <strong> {{showGasName(gas)}}</strong>
                                                                </div>
                                                                <!-- EDITAR GASES -->
                                                                <button mat-icon-button color="accent"
                                                                    *ngIf="gasTab === gasIndex"
                                                                    (click)="openDialogEditGas(indexWC,indexArea, gasIndex, true)"
                                                                    [disabled]="quoteDTO.idStatus > 1">
                                                                    <mat-icon
                                                                        style="font-size: 20px;">mode_edit</mat-icon>
                                                                </button>

                                                            </mat-panel-title>
                                                        </mat-expansion-panel-header>
                                                        <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                            <strong>{{ "quoteEdit.dialog.equipment.test" |
                                                                translate }}: </strong>
                                                            <div fxFlex="15" fxFlex.lt-md="100%"
                                                                *ngFor="let test of gas.tests">
                                                                {{ getTestName(test.idType) | translate }}
                                                            </div>
                                                        </div>
                                                        <div fxLayout="row" class="flexwrap" style="max-height: 50vh;">
                                                            <div fxFlex="15" fxFlex.lt-md="100%"
                                                                *ngFor="let qualification of gas.qualifications; let first = first">
                                                                <div *ngIf="first">
                                                                    <span><strong>{{
                                                                            "quoteEdit.dialog.equipment.cualifications"
                                                                            | translate }}:</strong></span>
                                                                </div>
                                                                <div>
                                                                    {{ getQualificationName(qualification.idType) |
                                                                    translate }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <br />

                                                <!-- BOTON AÑADIR EQUIPOS TERMICOS -->
                                                <mat-toolbar color="secondary">
                                                    {{ 'quoteEdit.EquipmentThermal.title' | translate }}
                                                </mat-toolbar>
                                                <!-- TERMICOS -->
                                                <mat-accordion>
                                                    <mat-expansion-panel class="overflowX" 
                                                        *ngFor="let  equipmentThermal of area.equpmentThermal; let gasIndex = index"
                                                        (afterExpand)="termalTab = thermalEquipmentIndex"
                                                        (closed)="termalTab = null">
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>
                                                                <div style="margin: auto 0;display: block;">
                                                                    {{ equipmentThermal.maker }}
                                                                    {{ equipmentThermal.model }} {{
                                                                    equipmentThermal.serialNumber }}
                                                                </div>
                                                                <!-- EDITAR TERMICOS -->
                                                                <button mat-icon-button color="accent"
                                                                    *ngIf="termalTab === thermalEquipmentIndex"
                                                                    (click)=" openDialogEditThermalEquioment(indexWC,indexArea, thermalEquipmentIndex, true)"
                                                                    [disabled]="quoteDTO.idStatus > 1">
                                                                    <mat-icon
                                                                        style="font-size: 20px;">mode_edit</mat-icon>
                                                                </button>
                                                                <!-- ELIMINAR TERMICOS -->
                                                                <button mat-icon-button color="accent"
                                                                    *ngIf="termalTab === thermalEquipmentIndex"
                                                                    (click)="deleteThermalEquioments(area, thermalEquipmentIndex)"
                                                                    [disabled]="quoteDTO.idStatus > 1">
                                                                    <mat-icon style="font-size: 20px;">delete</mat-icon>
                                                                </button>
                                                                <button mat-icon-button color="accent"
                                                                    *ngIf="asTab === thermalEquipmentIndex&& false"
                                                                    [disabled]="quoteDTO.idStatus > 1"
                                                                    (click)="duplicateEquipment(indexWC,indexArea, thermalEquipmentIndex)">
                                                                </button>
                                                            </mat-panel-title>
                                                        </mat-expansion-panel-header>
                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                                <br>
                                            </mat-tab>
                                        </mat-tab-group>
                                    </div>

                                    <div color="secondary"
                                        *ngIf="workcenter.equipmentThermal?.length > 0">
                                        <div class="panel-div-title subt-left" fxLayout="row">
                                            <div>
                                                {{'quoteEdit.general.thermal.title' | translate }}
                                            </div>

                                            <button mat-icon-button color="accent" (click)="openThermalConsultancy()">
                                                <mat-icon>expand_more</mat-icon>
                                            </button>
                                        </div>

                                        <mat-accordion *ngIf="showThermalConsultancy">
                                            <mat-expansion-panel class="overflowX"
                                                *ngFor="let equipmentThermal of workcenter.equipmentThermal; let indexThermal = index"
                                                (afterExpand)="ThermalTab = ThermalIndex" (closed)="ThermalTab = null">
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        <div fxLayout="column" style="margin-left: 20px;">
                                                            <div fxLayout="row">
                                                                <span>
                                                                    <strong>
                                                                        {{'equipmentType.equipment' |translate}}:
                                                                        {{equipmentThermal.equipment}}
                                                                        {{'(S/N:'}}
                                                                    </strong>
                                                                    {{ equipmentThermal.serialNumber || "NA" }}{{')'}}</span>
                                                                <span><strong>{{'(Id.'}} </strong>
                                                                    {{ equipmentThermal.identificator || "NA" }}{{')'}}</span>
                                                            </div>
        
                                                            <div fxLayout="row">
                                                                <span><strong> {{ 'quoteEdit.equipment.thermal.location' |
                                                                        translate }} </strong>
                                                                    {{ equipmentThermal.location }}</span>
                                                            </div>
                                                        </div>
                                                        <button mat-icon-button color="accent"
                                                        [disabled]="quoteDTO.idStatus > 1"
                                                            (click)="editEquipmentThermal(indexWC, indexThermal, true)">
                                                            <mat-icon>mode_edit</mat-icon>
                                                        </button>
                                                    </mat-panel-title>
                                                </mat-expansion-panel-header>

                                                <div class="flexwrap"
                                                    style="max-height: 50vh; display: flex; flex-wrap: wrap;">
                                                    <div style="width: 100%;">
                                                        <span><strong>{{ "quoteEdit.dialog.equipment.cualifications" |
                                                                translate }}:</strong></span>
                                                    </div>
                                                    <div *ngFor="let qualification of equipmentThermal.qualifications"
                                                        style="flex: 0 0 15%;">
                                                        <div>
                                                            {{ getQualificationName(qualification.idType) | translate }}
                                                        </div>
                                                    </div>
                                                </div>

                                            </mat-expansion-panel>
                                        </mat-accordion>

                                    </div>
                                    <!-- Calibrations -->
                                    <div color="secondary"
                                        *ngIf="workcenter.calibrations?.length > 0">
                                        <div class="panel-div-title subt-left" fxLayout="row">
                                            <div>
                                                {{'quoteEdit.general.calibration.title' | translate }}
                                            </div>
                                            <button mat-icon-button color="accent"
                                                (click)="openCalibrationConsultancy()">
                                                <mat-icon>expand_more</mat-icon>
                                            </button>
                                        </div>
                                        <div *ngIf="showCalibrationConsultancy">
                                            <mat-list>
                                                <mat-list-item
                                                    *ngFor="let calibration of workcenter.calibrations; let indexCalibration = index">
                                                    <div>

                                                        {{ showCalibrationName(calibration) | translate }}
                                                        <button mat-icon-button color="accent"
                                                            matTooltip="{{ 'button.edit' | translate }}"
                                                            [disabled]="quoteDTO.idStatus > 1"
                                                            (click)="openDialogEditCalibration(indexWC,indexCalibration)">
                                                            <mat-icon>mode_edit</mat-icon>
                                                        </button>
                                                    </div>
                                                </mat-list-item>
                                            </mat-list>
                                        </div>
                                    </div>
                                    <!-- BOTON AÑADIR CONSULTORIA -->
                                    <mat-toolbar color="secondary" class="tab-left">
                                        {{ 'quoteEdit.general.counsultory.title' | translate }}
                                    </mat-toolbar>

                                    <!-- ALCANCE CONSULTORIA -->
                                    <!-- TEXT AREA-->
                                    <div class="form-outline w-75 mb-4" fxFlex="50" fxLayout="column">
                                        <div fxFlex="50" fxLayout="column" fxFlex.lt-md="100%"
                                            style="height: min-content;">
                                            <div fxFlex="50" style="padding: 10px; width: 50%;" *ngIf="workcenter.consultancy != null">
                                                <quill-editor [(ngModel)]="workcenter.consultancy.consultancyScope" [name]="'wysiwygContent'+workcenter.id" class="text-rich-scope"
                                                [disabled]="quoteDTO.idStatus > 1"
                                                    [id]="'wysiwygContent'+workcenter.id" ></quill-editor>
                                            </div>
                                            <div style="padding: 10px">
                                                <button mat-raised-button color="secondary"
                                                    (click)="addConsultancyProfile(indexWC, 1)"
                                                    [disabled]="quoteDTO.idStatus > 1">
                                                    {{'quoteEdit.button.profiles' | translate}}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="container" fxFlex="50"
                                            style="padding: 10px">
                                            <div class="row"
                                                *ngFor="let profile of workcenter?.consultancyProfiles; let profileIndex = index">
                                                <div fxFlex="50" fxFlex.lt-md="100%">
                                                    <div fxFlex="25" fxLayout="column">
                                                        <mat-label> {{'quoteEdit.general.consultory.profile'|
                                                            translate}}
                                                        </mat-label>
                                                        <div fxFlex="25" fxFlex.lt-md="100%">
                                                            {{ profile.resourceCodeNav }}
                                                        </div>
                                                    </div>
                                                    <div fxFlex="25" fxLayout="column">
                                                        <mat-label> {{'quoteEdit.general.consultory.hours'|
                                                            translate}}:
                                                        </mat-label>
                                                        <div fxFlex="25" fxFlex.lt-md="100%">
                                                            {{ profile.hours }}h.
                                                        </div>
                                                    </div>
                                                    <div fxFlex="25" fxLayout="column">
                                                        <mat-label>
                                                            {{'quoteEdit.general.consultory.closedprice'|
                                                            translate}}:
                                                        </mat-label>
                                                        <div fxFlex="25" fxFlex.lt-md="100%">
                                                            {{profile.price | currency:'EUR'}}
                                                        </div>
                                                    </div>
                                                    <div fxFlex="25" fxLayout="column">
                                                        <mat-label> {{'quoteEdit.general.button.implant'|
                                                            translate}}:
                                                        </mat-label>
                                                        <div fxFlex="25" fxFlex.lt-md="100%">
                                                            <mat-icon> {{profile.implant ? 'check' :
                                                                'close'}}</mat-icon>
                                                        </div>
                                                    </div>

                                                    <button mat-icon-button color="accent"
                                                        (click)="openDialogEditConsultancyProfile(indexWC, profileIndex)"
                                                        [disabled]="quoteDTO.idStatus > 1">
                                                        <mat-icon>mode_edit</mat-icon>
                                                    </button>

                                                    <button mat-icon-button color="accent"
                                                        (click)="deleteConsultancyProfile(indexWC, profileIndex)"
                                                        [disabled]="quoteDTO.idStatus > 1">
                                                        <mat-icon style="font-size: 20px;">delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </mat-card-content>
                </mat-card>
            </mat-expansion-panel>
            <br *ngIf="quoteDTO.noScope"/>
            <mat-expansion-panel class="overflowX" *ngIf="quoteDTO.noScope && quoteDTO.id > 0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-toolbar color="secondary">
                            {{ 'quoteEdit.noscope.title' | translate }}
                        </mat-toolbar>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-card class="card">
                    <mat-card-content>
                        <div fxLayout="column" > 
                            <input matInput name="valorNoScope" [(ngModel)]="quoteDTO.totalAmount"
                            type="number" min="0"
                            placeholder="{{ 'quoteEdit.general.noScope.totalprice.label' | translate }}">
                        </div>
                    </mat-card-content>
                </mat-card>
            </mat-expansion-panel>
            <br />
            <mat-expansion-panel class="overflowX" *ngIf=" quoteDTO.id > 0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-toolbar color="secondary">
                            {{ 'quoteEdit.information.title' | translate }}
                        </mat-toolbar>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                 <!-- Information -->
                <mat-card class="card" *ngIf=" quoteDTO.id > 0">
                    <mat-card-content>
                        <div fxLayout="column" style="height: 200px;"> 
                            <div fxLayout="column" fxFlex.lt-md="90%" style="height: 90%;">
                            <div class="form-outline w-75 mb-4" style="height: calc(100% - 20px);"> 
                                <textarea matInput name="messaInfo" [disabled]="quoteDTO.idStatus > 1"   placeholder="Información comercial" 
                                    #message='ngModel' [(ngModel)]="quoteDTO.information" class="text-rich-scope" style="font-size: 12px;"></textarea>
                            </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </mat-expansion-panel>

              <br />
            <mat-expansion-panel class="overflowX" *ngIf="quoteDTO.id !=null">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-toolbar color="secondary">
                            {{ 'quoteEdit.general.tab.title' | translate }}
                        </mat-toolbar>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-quote-detail #quoteDetailComponent *ngIf="quoteDTO !=null" [quote]="quoteDTO"></app-quote-detail>
            </mat-expansion-panel>

            <app-quote-edit-attachments #attachmentsComponen *ngIf=" quoteDTO.id > 0" ></app-quote-edit-attachments>
            <mat-card class="card" style="text-align: end;">
                <mat-card-actions>
                    <button mat-raised-button color="primary" (click)="confirmationHours()" *ngIf="showSave()">
                        {{ saveMessage | translate }} </button>
                    <button mat-raised-button color="secondary" (click)="advance()"
                        *ngIf="(quoteDTO.idStatus| quoteStatus: 'preparation') && quoteDTO.id != null">
                        {{ 'button.advance' | translate }} </button>
                    <button mat-raised-button color="secondary" (click)="send()"
                        *ngIf="(quoteDTO.idStatus | quoteStatus: 'signed')">
                        {{ 'button.send' | translate }} </button>
                    <button mat-raised-button color="secondary" (click)="retreat()"
                        *ngIf="(quoteDTO.idStatus | quoteStatus: 'signed')">
                        {{ 'button.retreat' | translate }} </button>
                    <button mat-raised-button color="secondary" (click)="sendWon()"
                        *ngIf="(quoteDTO.idStatus | quoteStatus: 'sent')">
                        {{ 'button.won' | translate }} </button>
                    <button mat-raised-button color="secondary" (click)="sendLost()"
                        *ngIf="(quoteDTO.idStatus | quoteStatus: 'sent')">
                        {{ 'button.lost' | translate }} </button>
                    <button mat-raised-button color="secondary" (click)="download()" *ngIf="quoteDTO.id != null && !quoteDTO.noScope">
                        {{ 'button.download' | translate }}</button>
                    <button mat-raised-button color="primary" (click)="downloadAttachmentNoScope(1)" *ngIf="quoteDTO.noScope && isPdfNoScope">
                        {{ 'button.downloadPdf' | translate }}</button>
                    <button mat-raised-button color="primary" (click)="downloadAttachmentNoScope(2)" *ngIf="quoteDTO.noScope && isWordNoScope ">
                        {{ 'button.downloadWord' | translate }}</button>
                    <button mat-raised-button color="secondary" (click)="saveQuoteVersion()"
                        *ngIf="(quoteDTO.idStatus === 3 || quoteDTO.idStatus === 4 || quoteDTO.idStatus === 5) && quoteDTO.versionControl">
                        {{ 'button.version' | translate }}</button>
                    <button mat-raised-button color="secondary" (click)="cancel()">
                        {{ 'button.cancel' | translate }}</button>
                </mat-card-actions>
            </mat-card>
        </form>
    </mat-tab>
    <mat-tab #quoteTab label="{{ 'quoteEdit.version.title' | translate }}" *ngIf="quoteDTO.idVersionControl != null">
        <form>
            <mat-card class="card">
                <mat-card-content>
                    <mat-list>
                        <mat-list-item *ngFor="let v of quoteDTOList; let indexx = index">
                            <div fxFlex="100">
                                <div fxFlex="50">
                                    <div fxFlex="25">
                                    v{{ v.numVersion }}
                                    </div>
                                    <div fxFlex="75">
                                        <span *ngIf="indexx === quoteDTOList.length - 1">
                                            {{ quoteDTO.newVersionReason }}
                                        </span>
                                    </div>
                                </div>
                                <div fxFlex="50">
                                    <div fxFlex="50" style="text-align: right;">
                                        <button mat-raised-button color="secondary" (click)="editVersion(v?.id)"
                                            *ngIf="quoteDTO.id != null">
                                            {{ 'button.edit' | translate }}</button>
                                    </div>
                                    <div fxFlex="50" style="text-align: right;">
                                        <button mat-raised-button color="secondary" (click)="downloadVersion(v)"
                                            *ngIf="quoteDTO.id != null">
                                            {{ 'button.download' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </form>
    </mat-tab>
</mat-tab-group>