<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
  <div mat-dialog-content class="air-theme">

    <div fxLayout="row" class="flexwrap">
      <div fxFlex="35" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'quoteEdit.general.dietTravel.label' | translate }}</mat-label>
          <mat-select required name="idDiet"
              [(ngModel)]="data.diet.dietCodeNav">
              <mat-select-filter [displayMember]="'Description'" [array]="dietTravels"
              (filteredReturn)="dietTravelsFiltered =$event"
              [noResultsMessage]="'common.noResults' | translate"
              [placeholder]="'common.selectFilterPlaceholder' | translate">
              </mat-select-filter>
              <mat-option *ngFor="let diet of dietTravelsFiltered" [value]="diet.Code">
                  {{ diet.Description | translate }}
              </mat-option>
          </mat-select>
          <mat-error>
              {{ 'quoteEdit.dialog.area.name.error' | translate }}
          </mat-error>
      </mat-form-field>
      </div>
      <div fxFlex="15" fxFlex.lt-md="100%" >
        <mat-form-field class="campoFormulario">
          <input matInput [(ngModel)]="data.diet.amount" name="amount" type="number"
            placeholder="{{ 'quoteEdit.dialog.dietTravel.amount.label' | translate }}">
        </mat-form-field>
      </div>
      <div fxFlex="25" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'quoteEdit.dialog.departement.label' | translate }}</mat-label>
          <mat-select name="departement" [(ngModel)]="data.diet.departmentCodeNav">
            <mat-select-filter [displayMember]="'Code'" [array]="departements"
              (filteredReturn)="departementsFiltered =$event"
              [noResultsMessage]="'common.noResults' | translate"
              [placeholder]="'common.selectFilterPlaceholder' | translate">
              </mat-select-filter>
            <mat-option *ngFor="let item of departementsFiltered" [value]="item.Code">
              {{ item.Code }}
            </mat-option>
          </mat-select>                                          
        </mat-form-field>
      </div>
      <div fxFlex="25" fxFlex.lt-md="100%">
        <mat-form-field class="campoFormulario">
          <mat-label>{{ 'quoteEdit.dialog.resource.label' | translate }}</mat-label>
          <mat-select name="idR" [(ngModel)]="data.diet.resourceCodeNav">
            <mat-select-filter [displayMember]="'No'" [array]="resources"
              (filteredReturn)="resourcesFiltered =$event"
              [noResultsMessage]="'common.noResults' | translate"
              [placeholder]="'common.selectFilterPlaceholder' | translate">
              </mat-select-filter>
            <mat-option *ngFor="let item of resourcesFiltered" [value]="item.No">
              {{ item.No }}
            </mat-option>
          </mat-select>                                          
        </mat-form-field>
      </div>
      
    </div>



  </div>
  <div mat-dialog-actions class="air-theme">
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
    <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
  </div>

</form>