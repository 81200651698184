import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { ConsultancyProfile } from 'src/app/model/consultancyProfile';
import { ProfileConsultancyType } from 'src/app/model/profileConsultancyType';
import { ResourceQualiApi } from 'src/app/model/resourceQualiApi';
import { ProfileConsultancyTypeService } from 'src/app/service/profileConsultancyType.service';
import { ResourceQualiApiService } from 'src/app/service/resourceQualiApi.service';
import { SnackBarService } from 'src/app/service/snackBar.service';
import { Constants } from 'src/app/utils/constants';

export interface DialogDataProfileConsultancy{
  profile: ConsultancyProfile;
  isEdit: boolean;
}

@Component({
  selector: 'app-quote-edit-dialog-profile',
  templateUrl: './quote-edit-dialog-profile.component.html'
})
export class QuoteEditDialogProfileComponent implements OnInit {

  profileConsultancyType: ProfileConsultancyType[] = [];
  showClosedPrice: boolean;
  resources: ResourceQualiApi[];

  constructor(
    public snackBarService: SnackBarService,
    private profileConsultancyTypeService: ProfileConsultancyTypeService,
    private resourceQualiApiService: ResourceQualiApiService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<QuoteEditDialogProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataProfileConsultancy
  ) { }

  ngOnInit(): void {
    this.profileConsultancyTypeService.findAll().subscribe((res: ProfileConsultancyType[]) => {
      this.profileConsultancyType = res;
    });
    this.resourceQualiApiService.findAll().subscribe((res: ResourceQualiApi[]) => {
      this.resources = res;
    });
  }

  onOkClick(): void {
    let errs: string[] = [];

      if (this.data.profile.idProfile == null && this.data.profile.resourceCodeNav == null) {
        errs.push(this.translate.instant('profile.dialog.profile.type.error') as string);
      }
      if (this.data.profile.hours == null) {
        errs.push(this.translate.instant('profile.dialog.profile.hours.error') as string);
      }
      if (errs.length === 0) {
        this.data.profile.price = this.getPrice();
        this.dialogRef.close(this.data.profile);
      } else {
        this.snackBarService.sendError(errs.join('\n'));
      }
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConsultorytypeChance(event: MatSelectChange): void {
    let type: number = event.value;
    this.showClosedPrice = (type === Constants.PROFILE_TYPE_CLOSED_PRICE);
  }
  
  private getPrice() : number {
    let price = 0;
    //let profile: ProfileConsultancyType;
   // profile = this.profileConsultancyType.find(p => p.id == this.data.profile.idProfile);
    //price = this.data.profile.hours * profile.hourPrice;

    return price;
  }
}
